import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AppUrl } from '../../../core/models/app-url.enum';
import { MobileAppCustomizedEvent, MobileAppEventId } from '../../../core/models/mobile-app-send-event.model';
import { MobileAppService } from '../../../core/services/mobile-app.service';
import { MobileAppEventsService } from '../../../core/services/mobile-app-events.service';
import { ToastType } from '../../../shared/components/toast/models/toast-type.enum';
import { toastActions } from '../../../shared/components/toast/store/toast.actions';
import { AppState } from '../../../store';
import { Certificate } from '../models/certificate.model';
import { CertificateType } from '../models/certificate-type.enum';
import { certificatesActions } from '../store/certificates.actions';
import { CertificatesDataService } from './certificates.data.service';

@Injectable({ providedIn: 'root' })
export class CertificatesGeneralService {
  private store = inject(Store<AppState>);
  private certificatesDataService = inject(CertificatesDataService);
  private mobileAppService = inject(MobileAppService);
  private mobileAppEventsService = inject(MobileAppEventsService);
  private translateService = inject(TranslateService);
  private router = inject(Router);

  loadCustomCertificates(): Observable<Certificate[]> {
    this.store.dispatch(certificatesActions.setCustomCertificates({ customCertificates: null }));

    return this.certificatesDataService.getCertificates(CertificateType.CustomCertificate).pipe(
      tap((customCertificates: Certificate[]) => {
        this.store.dispatch(certificatesActions.setCustomCertificates({ customCertificates }));
      }),
    );
  }

  navigateToCertificatesPage(): void {
    if (this.mobileAppService.isMobileAppWebview()) {
      this.mobileAppEventsService.notifyMobile({ eventId: MobileAppEventId.CertificatesNavigation, closeWebview: true });
    } else {
      this.router.navigateByUrl(AppUrl.Certificates);
    }
  }

  goBackToCertificatesWithError(errorMessageTranslationKey?: string): void {
    if (this.mobileAppService.isMobileAppWebview()) {
      this.notifyMobileTechnicalError(errorMessageTranslationKey);
    } else {
      if (errorMessageTranslationKey) {
        this.store.dispatch(toastActions.showToast({ toastType: ToastType.Error, message: errorMessageTranslationKey }));
      } else {
        this.store.dispatch(toastActions.showGeneralErrorToast());
      }
      this.navigateToCertificatesPage();
    }
  }

  private notifyMobileTechnicalError(errorMessageTranslationKey = 'GENERAL.ERRORS.TOAST.HEADER'): void {
    const technicalErrorEvent: MobileAppCustomizedEvent = {
      eventId: MobileAppEventId.TechnicalError,
      errorMessage: this.translateService.instant(errorMessageTranslationKey || 'GENERAL.ERRORS.TOAST.HEADER'),
      closeWebview: true,
    };
    this.mobileAppEventsService.notifyMobile(technicalErrorEvent);
  }
}
