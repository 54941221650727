import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ButtonType } from '@next-insurance/ni-material';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';

import { BusinessService } from '../../../../../business/business.service';
import { Business } from '../../../../../business/models/business.model';
import { UpdatePersonalDetailsResponse } from '../../../../../business/models/update-personal-details.model';
import { AppUrl } from '../../../../../core/models/app-url.enum';
import { ToastType } from '../../../../../shared/components/toast/models/toast-type.enum';
import { toastActions } from '../../../../../shared/components/toast/store/toast.actions';
import { catchErrorAndLog } from '../../../../../shared/utils/catch-error-and-log.utils';
import { AppState } from '../../../../../store';

@Component({
  selector: 'ni-onboarding-edit-identity',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class OnboardingEditIdentityComponent implements OnInit {
  @Output() finished = new EventEmitter<boolean>();
  @Output() abortOnboarding = new EventEmitter<boolean>();
  editIdentityForm: UntypedFormGroup;
  identityValue$: Observable<string>;

  isSubmitting: boolean;
  ButtonType = ButtonType;

  abstract trackEditIdentitySubmitClicked(): void;

  abstract getIdentityValue$(): Observable<string>;

  abstract trackEditIdentityCancelClicked(): void;

  abstract getEditIdentityForm(): UntypedFormGroup;

  abstract trackEditIdentitySubmitResult(isSuccess: boolean, niStatusCode?: string): void;

  abstract updateContactInfo(): Observable<UpdatePersonalDetailsResponse>;

  protected constructor(
    protected store: Store<AppState>,
    protected changeDetectorRef: ChangeDetectorRef,
    protected businessService: BusinessService,
    protected router: Router,
  ) {}

  ngOnInit(): void {
    this.identityValue$ = this.getIdentityValue$();
    this.editIdentityForm = this.getEditIdentityForm();
  }

  submit(): void {
    this.setIsSubmitting(true);
    this.trackEditIdentitySubmitClicked();
    this.trimControlsValue();
    this.updateContactInfo()
      .pipe(
        switchMap(() => {
          this.trackEditIdentitySubmitResult(true);
          return this.loadBusiness();
        }),
        tap(() => {
          this.finished.emit(true);
        }),
        catchErrorAndLog((error: HttpErrorResponse) => {
          this.trackEditIdentitySubmitResult(false, error.error?.niStatusCode);
          if (error.error?.niStatusCode === 'EMAIL_ADDRESS_ALREADY_IN_USE') {
            this.store.dispatch(
              toastActions.showToast({
                toastType: ToastType.Error,
                message: 'EDIT_CONTACT_INFO.ERRORS.EMAIL_ADDRESS_ALREADY_IN_USE',
              }),
            );
          } else {
            this.store.dispatch(toastActions.showGeneralErrorToast());
            this.abortOnboarding.emit();
          }
          return of(false);
        }),
        finalize(() => {
          this.setIsSubmitting(false);
        }),
      )
      .subscribe();
  }

  private trimControlsValue(): void {
    for (const field in this.editIdentityForm.controls) {
      const control: AbstractControl = this.editIdentityForm.get(field);
      control.setValue(control.value.trim());
    }
  }

  cancel(): void {
    this.trackEditIdentityCancelClicked();
    this.finished.emit(false);
  }

  private loadBusiness(): Observable<Business> {
    return this.businessService.loadBusiness().pipe(
      catchErrorAndLog(() => {
        this.router.navigateByUrl(AppUrl.Error);
        return of(null);
      }),
    );
  }

  private setIsSubmitting(isSubmitting: boolean): void {
    this.isSubmitting = isSubmitting;
    this.changeDetectorRef.markForCheck();
  }
}
