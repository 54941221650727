import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ExchangeCodeResponse, TargetApp } from '../../core/models/target-app.model';

@Injectable({
  providedIn: 'root',
})
export class LoginV2DataService {
  constructor(private httpClient: HttpClient) {}

  /* istanbul ignore next */
  logout(): Observable<void> {
    return this.httpClient.post<void>('/api/public/authentication/logout/v2', {});
  }

  /* istanbul ignore next */
  exchangeCodeForTokens(code: string, codeVerifier: string): Observable<ExchangeCodeResponse> {
    return this.httpClient.get<ExchangeCodeResponse>(`/api/public/authentication/exchange-code-for-tokens`, {
      params: {
        code,
        codeVerifier,
      },
    });
  }

  /* istanbul ignore next */
  productRedirectExchangeCode(code: string, codeVerifier: string): Observable<TargetApp> {
    return this.httpClient
      .get<{ targetApp: TargetApp }>(`/api/public/authentication/product-redirect-exchange-code`, {
        params: {
          code,
          codeVerifier,
        },
      })
      .pipe(
        map((res: { targetApp: TargetApp }) => {
          return res.targetApp;
        }),
      );
  }

  /* istanbul ignore next */
  setVerifier(verifier: string): Observable<void> {
    return this.httpClient.post<void>('/api/public/authentication/set-verifier', { verifier });
  }

  /* istanbul ignore next */
  oktaExchangeCode(code: string): Observable<void> {
    // Note: This API gets it verifier secret from Robin,
    // [Robin]src/app/shared/services/portal-authentication.service.ts
    // Method: setVerifierCookieForPortal (In case it's not changed by now)
    return this.httpClient.get<void>(`/api/public/authentication/okta-exchange-code`, {
      params: {
        code,
      },
    });
  }
}
