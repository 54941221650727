import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CobSegment } from '../core/models/cob-segment.enum';
import { BusinessMoratoriums } from '../home/models/business-moratoriums.model';
import { AuthorizedUser, AuthorizedUserRequest } from './models/authorized-user.model';
import { Business } from './models/business.model';
import { BusinessGeneralInfo } from './models/business-general-info.model';
import { BusinessOwner } from './models/business-owner.model';
import { BusinessPeopleInfo } from './models/business-people-info.model';
import { BusinessPropertyInfo } from './models/business-property-info.model';
import { CertificateActivity } from './models/certificate-activity.model';
import { Driver } from './models/driver.model';
import { Vehicle } from './models/vehicle.model';

@Injectable({
  providedIn: 'root',
})
export class BusinessDataService {
  constructor(private httpClient: HttpClient) {}

  /* istanbul ignore next */
  getBusiness(): Observable<Business> {
    return this.httpClient.get<Business>('/api/business/details');
  }

  /* istanbul ignore next */
  getMoratoriums(): Observable<BusinessMoratoriums> {
    return this.httpClient.get<BusinessMoratoriums>('api/business/moratoriums');
  }

  /* istanbul ignore next */
  getCertificatesActivities(numberOfActivities: number): Observable<CertificateActivity[]> {
    return this.httpClient.get<CertificateActivity[]>('api/business/certificate-activities', {
      params: {
        numberOfActivities: numberOfActivities.toString(),
      },
    });
  }

  /* istanbul ignore next */
  getVehicles(): Observable<Vehicle[]> {
    return this.httpClient.get<Vehicle[]>('api/business/vehicles');
  }

  /* istanbul ignore next */
  getDrivers(): Observable<Driver[]> {
    return this.httpClient.get<Driver[]>('api/business/drivers');
  }

  /* istanbul ignore next */
  getGeneralInfo(): Observable<BusinessGeneralInfo> {
    return this.httpClient.get<BusinessGeneralInfo>('api/business/general-info');
  }

  /* istanbul ignore next */
  getPeopleInfo(): Observable<BusinessPeopleInfo> {
    return this.httpClient.get<BusinessPeopleInfo>('api/business/people-info');
  }

  getPropertyInfo(): Observable<BusinessPropertyInfo> {
    return this.httpClient.get<BusinessPropertyInfo>('api/business/property-info').pipe(
      map((propertyInfo: BusinessPropertyInfo) => {
        return Object.values(propertyInfo).some((field) => !!field) ? propertyInfo : null;
      }),
    );
  }

  /* istanbul ignore next */
  getOwners(): Observable<BusinessOwner[]> {
    return this.httpClient.get<BusinessOwner[]>('api/business/owners');
  }

  /* istanbul ignore next */
  addAuthorizedUser(authorizedUser: AuthorizedUserRequest): Observable<void> {
    return this.httpClient.post<void>('/api/business/authorized-users', { ...authorizedUser });
  }

  /* istanbul ignore next */
  getAuthorizedUsers(): Observable<AuthorizedUser[]> {
    return this.httpClient.get<AuthorizedUser[]>('/api/business/authorized-users');
  }

  /* istanbul ignore next */
  deleteAuthorizedUser(authorizedUserId: string): Observable<void> {
    return this.httpClient.delete<void>('/api/business/authorized-users', {
      params: {
        authorizedUserId,
      },
    });
  }

  /* istanbul ignore next */
  getSegmentByCob(cobId: number): Observable<CobSegment> {
    return this.httpClient.get<CobSegment>('/api/cob-segment', { params: { cobId } });
  }
}
