<div class="loader" *ngIf="isLoading" [ngClass]="{ 'full-screen': isFullScreen }">
  <div class="loader-wrapper">
    <div class="loader-container">
      <img class="loading-animation" src="assets/animations/loading-rainbow.gif" alt="loading-animation" />
      @if (loadingText) {
        <div class="h6_h4-regular">{{ loadingText }}</div>
      }
    </div>
  </div>
</div>
