import { PaymentMethodType } from '../models/payment-method-details.model';

export const paymentMethodIconsConfig = {
  [PaymentMethodType.CreditCard]: {
    path: '/assets/img/credit-card-icons/credit-card.svg',
    alt: 'credit-card-icon',
  },
  [PaymentMethodType.BankAccount]: {
    path: '/assets/img/bank-account.svg',
    alt: 'bank-account-icon',
  },
};
