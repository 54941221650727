import { Action, createReducer, on } from '@ngrx/store';

import { PaymentDetailsPerPolicy } from '../models/payment-details-per-policy.model';
import { PaymentMethodDetails } from '../models/payment-method-details.model';
import { paymentActions } from './payment.actions';

export interface PaymentState {
  paymentMethodDetails: PaymentMethodDetails;
  paymentDetailsPerPolicy: PaymentDetailsPerPolicy;
  isLoaded: boolean;
  isLoading: boolean;
}

export const paymentInitialState: PaymentState = {
  paymentMethodDetails: null,
  paymentDetailsPerPolicy: null,
  isLoaded: false,
  isLoading: false,
};

const reducer = createReducer(
  paymentInitialState,
  on(paymentActions.setIsLoading, (state, { isLoading }) => ({
    ...state,
    isLoading,
  })),
  on(paymentActions.setCombinedPaymentDetails, (state, { combinedPaymentDetails }) => ({
    ...state,
    paymentMethodDetails: combinedPaymentDetails?.paymentMethodDetails,
    paymentDetailsPerPolicy: combinedPaymentDetails?.paymentDetailsPerPolicy,
    isLoaded: true,
    isLoading: false,
  })),
);

export function paymentReducer(state: PaymentState, action: Action): PaymentState {
  return reducer(state, action);
}
