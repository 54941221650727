import { createAction, props } from '@ngrx/store';

import { PackageDataPerPolicy } from '../../core/models/package-data.model';
import { PoliciesInfoResponse } from '../models/policies-info-response.model';

const setIsLoadingPolicies = createAction('[Policies] Set is loading policies', props<{ isLoading: boolean }>());

const setPolicies = createAction(
  '[Policies] Set Policies',
  props<{ policiesInfo: PoliciesInfoResponse; preventMobileBusinessChangeEvent?: boolean }>(),
);

const setPackageData = createAction('[Polices] Set Policies Package Data', props<{ policiesPackageData: PackageDataPerPolicy }>());

export const policiesActions = {
  setIsLoadingPolicies,
  setPolicies,
  setPackageData,
};
