export enum AppUrl {
  EmptyPage = '/empty-page',
  Home = '/home',
  Error = '/error',
  Billing = '/billing',
  Certificates = '/certificates',
  Claims = '/claims',
  Coverage = '/coverage',
  DocumentCenter = '/document-center',
  Followers = '/followers',
  Business = '/business',
  EditContactInfo = '/business/contact/edit',
  EditRevenue = '/business/revenue/edit',
  AddLocation = '/business/add-location',
  EditLocation = '/business/edit-address',
  RemoveLocation = '/business/remove-location',
  EditMailingAddress = '/business/mailing-address/edit',
  AddAuthorizedUser = '/business/add-authorized-user',
  CertificatesAutoTab = '/certificates/auto-insurance-cards',
  ThirdPartyChangesRequestReview = '/certificates/third-party-requests/changes',
  ThirdPartyNotificationsRequestReview = '/certificates/third-party-requests/notifications',
  LiveCertificate = '/public/certificates/live-certificate',
  LiveCertificateError = '/public/certificates/live-certificate/error',
  CreateCustomCertificate = '/certificates/create-certificate',
  CreateCustomCertificateReview = '/certificates/create-certificate/review',
  CreateCustomCertificateOptions = '/certificates/create-certificate/options',
  CreateCustomCertificatePreQuestions = '/certificates/create-certificate/pre-questions',
  CreateCustomCertificateCustomizations = '/certificates/create-certificate/customizations',
}

export enum AppRelativeUrl {
  PolicyCoverageCustomization = 'customization',
  LiveCertificateRequestChanges = 'third-party-requests/changes',
  LiveCertificateRequestNotifications = 'third-party-requests/notifications',
  LiveCertificateRequestChangesCustomizations = 'customizations',
  LiveCertificateRequestChangesReview = 'review',
}
