import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, first, map, skipWhile, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { UserType } from '../../core/models/user-type.enum';
import { CommonConfigDataService } from '../../core/services/common-config.data.service';
import { DynamicDialogService } from '../../core/services/dynamic-dialog.service';
import { MobileAppService } from '../../core/services/mobile-app.service';
import { coreSelectors } from '../../core/store/core.selectors';
import { CrossSellPolicy } from '../../cross-sell/models/cross-sell-policy.model';
import { CrossSellDataService } from '../../cross-sell/services/cross-sell.data.service';
import { CrossSellService } from '../../cross-sell/services/cross-sell.service';
import { CrossSellTrackingService } from '../../cross-sell/services/cross-sell-tracking.service';
import { crossSellSelectors } from '../../cross-sell/store/cross-sell.selectors';
import { toastActions } from '../../shared/components/toast/store/toast.actions';
import { catchErrorAndLog } from '../../shared/utils/catch-error-and-log.utils';
import { AppState } from '../../store';
import { CoverageCheckupModalComponent } from '../components/coverage-checkup-modal/coverage-checkup-modal.component';
import { CoverageRiskLevel } from '../enums/coverage-risk-level.enum';
import { BusinessPropertiesType } from '../models/business-properties-type.enum';
import { CoverageCheckupDetails, LobCoverageDetails } from '../models/coverage-checkup-details.model';
import { ExternalLobAnswer } from '../models/external-lob-answer.model';

@Injectable({
  providedIn: 'root',
})
export class CoverageCheckupService {
  private store = inject(Store<AppState>);
  private mobileAppService = inject(MobileAppService);
  private router = inject(Router);
  private crossSellTrackingService = inject(CrossSellTrackingService);
  private dynamicDialogService = inject(DynamicDialogService);
  private crossSellDataService = inject(CrossSellDataService);
  private crossSellService = inject(CrossSellService);

  private readonly coverageCheckupModalHasBeenOpenedStorageKey = 'coverageCheckupModalHasBeenOpened';
  private readonly excludedUrls = [...CommonConfigDataService.getExcludedUrlsForPopup(), /\/claims.*/];
  private businessPropertiesAnswersSubject$: BehaviorSubject<BusinessPropertiesType[]> = new BehaviorSubject<BusinessPropertiesType[]>(
    null,
  );

  private externalLobsAnswersSubject$: BehaviorSubject<ExternalLobAnswer[]> = new BehaviorSubject<ExternalLobAnswer[]>(null);
  private isSubmittingAnswersSubject$ = new BehaviorSubject(false);

  isSubmittingAnswers$ = this.isSubmittingAnswersSubject$.asObservable();

  getLastBusinessPropertiesSavedAnswers(): Observable<BusinessPropertiesType[]> {
    return this.businessPropertiesAnswersSubject$.asObservable().pipe(
      take(1),
      filter((businessPropertiesAnswers: BusinessPropertiesType[]) => !!businessPropertiesAnswers),
    );
  }

  getLastExternalLobsSavedAnswers(): Observable<ExternalLobAnswer[]> {
    return this.externalLobsAnswersSubject$.asObservable().pipe(
      take(1),
      filter((externalLobsAnswers: ExternalLobAnswer[]) => !!externalLobsAnswers),
    );
  }

  canCoverageCheckupModalBeOpened(): boolean {
    const isModalOpenedBefore = this.getIsModalOpenedBefore();
    const isATeamUser = this.getIsATeamUser();
    const isLandedWithOperation = this.getIsLandedWithOperation();
    const shouldOpenInCurrentURL = this.shouldOpenInCurrentURL();
    const isMobileWebView = this.mobileAppService.isMobileAppWebview();
    const isUserLoggedIn = this.getIsUserLoggedIn();

    return !isModalOpenedBefore && !isATeamUser && !isLandedWithOperation && shouldOpenInCurrentURL && !isMobileWebView && isUserLoggedIn;
  }

  loadAndOpenCoverageCheckupModalIfEligible(): void {
    if (!this.canCoverageCheckupModalBeOpened()) {
      return;
    }

    const isCrossSellFetched = this.isCrossSellFetched();
    let waitForStateToBeNull = false;
    // Optimization: don't load cross-sell if it's already fetched, e.g. on home or coverage page
    if (
      !isCrossSellFetched &&
      ![/^\/home$/, /^\/coverage$/].some((excludedUrlPattern: RegExp) => this.router.url.match(excludedUrlPattern))
    ) {
      this.crossSellService.loadCrossSell().subscribe();
      waitForStateToBeNull = true;
    }

    this.store
      .select(crossSellSelectors.getIsEligibleForCoverageCheckup)
      .pipe(
        waitForStateToBeNull ? skipWhile((isEligibleForCoverageCheckup: boolean) => isEligibleForCoverageCheckup !== null) : tap(),
        first((isEligibleForCoverageCheckup: boolean) => isEligibleForCoverageCheckup !== null),
        withLatestFrom(this.store.select(crossSellSelectors.getCoverageCheckupDetails)),
        filter(
          ([isEligibleForCoverageCheckup, coverageCheckupDetails]: [boolean, CoverageCheckupDetails]) =>
            isEligibleForCoverageCheckup && coverageCheckupDetails.coverageRiskLevel !== CoverageRiskLevel.Low,
        ),
        tap(() => {
          this.openCoverageCheckupModal(true);
          this.setIsModalOpenedBeforeToTrue();
        }),
      )
      .subscribe();
  }

  private isCrossSellFetched(): boolean {
    let isCrossSellFetched = false;
    this.store
      .select(crossSellSelectors.getPolicies)
      .pipe(take(1))
      .subscribe((crossSellPolicies: CrossSellPolicy[]) => {
        if (crossSellPolicies?.length > 0) {
          isCrossSellFetched = true;
        }
      });
    return isCrossSellFetched;
  }

  openCoverageCheckupModal(isOpenedAutomatic = false): DynamicDialogRef {
    if (isOpenedAutomatic) {
      this.crossSellTrackingService.trackAutomaticOpenCoverageCheckupModal();
    } else {
      this.crossSellTrackingService.trackManualOpenCoverageCheckupModal();
    }

    // Inserting a string with just space so that the header will always render
    return this.dynamicDialogService.open(CoverageCheckupModalComponent, {
      header: ' ',
      showHeader: true,
    });
  }

  private getIsUserLoggedIn(): boolean {
    let isUserLoggedIn = false;
    this.store
      .select(coreSelectors.isUserLoggedIn)
      .pipe(
        take(1),
        map((isLoggedIn: boolean) => {
          isUserLoggedIn = isLoggedIn;
        }),
      )
      .subscribe();
    return isUserLoggedIn;
  }

  private shouldOpenInCurrentURL(): boolean {
    return !this.excludedUrls.some((excludedUrlPattern) => this.router.url.match(excludedUrlPattern));
  }

  getCriticalCoveragesCrossSell(crossSellPolicies: CrossSellPolicy[], lobsCoverageDetails: LobCoverageDetails[]): CrossSellPolicy[] {
    return crossSellPolicies.filter((crossSellPolicy: CrossSellPolicy) =>
      lobsCoverageDetails.some(
        (lobCoverageDetails: LobCoverageDetails) =>
          lobCoverageDetails.lob === crossSellPolicy.lob && lobCoverageDetails.required && !lobCoverageDetails.alreadyCovered,
      ),
    );
  }

  getOptionalCoveragesCrossSell(crossSellPolicies: CrossSellPolicy[], lobsCoverageDetails: LobCoverageDetails[]): CrossSellPolicy[] {
    const criticalCoveragesCrossSell = this.getCriticalCoveragesCrossSell(crossSellPolicies, lobsCoverageDetails);
    return crossSellPolicies.filter(
      (crossSellPolicy) => !criticalCoveragesCrossSell.some((criticalCrossSell) => crossSellPolicy.lob === criticalCrossSell.lob),
    );
  }

  private setIsModalOpenedBeforeToTrue(): void {
    localStorage.setItem(this.coverageCheckupModalHasBeenOpenedStorageKey, 'true');
  }

  private getIsModalOpenedBefore(): boolean {
    return localStorage.getItem(this.coverageCheckupModalHasBeenOpenedStorageKey) === 'true';
  }

  private getIsATeamUser(): boolean {
    let result;
    this.store
      .select(coreSelectors.getUserType)
      .pipe(take(1))
      .subscribe((userType: UserType) => {
        result = userType === UserType.ATeam;
      });

    return result;
  }

  private getIsLandedWithOperation(): boolean {
    let isLandedWithOperation: boolean;
    this.store
      .select(coreSelectors.isLandedWithOperation)
      .pipe(first())
      .subscribe((isWithOperation: boolean) => {
        isLandedWithOperation = isWithOperation;
      });

    return isLandedWithOperation;
  }

  saveBusinessPropertiesAnswers(businessPropertiesAnswers: BusinessPropertiesType[]): void {
    this.businessPropertiesAnswersSubject$.next(businessPropertiesAnswers);
  }

  saveExternalLobsAnswers(externalLobsAnswers: ExternalLobAnswer[]): void {
    this.externalLobsAnswersSubject$.next(externalLobsAnswers);
  }

  updateAnswers(): Observable<boolean> {
    this.isSubmittingAnswersSubject$.next(true);
    return combineLatest([this.businessPropertiesAnswersSubject$, this.externalLobsAnswersSubject$]).pipe(
      take(1),
      switchMap(([businessPropertiesAnswers, externalLobsAnswers]: [BusinessPropertiesType[], ExternalLobAnswer[]]) =>
        this.crossSellDataService.updateCoverageCheckupAnswers(businessPropertiesAnswers, externalLobsAnswers).pipe(
          catchErrorAndLog(() => {
            this.store.dispatch(toastActions.showGeneralErrorToast());
            this.isSubmittingAnswersSubject$.next(false);
            return of(null);
          }),
        ),
      ),
      switchMap(() => {
        return this.crossSellService.loadCrossSell();
      }),
      map(() => {
        this.isSubmittingAnswersSubject$.next(false);
        return true;
      }),
    );
  }

  resetSavedAnswers(): void {
    this.externalLobsAnswersSubject$.next(null);
    this.businessPropertiesAnswersSubject$.next(null);
  }
}
