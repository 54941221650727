import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WINDOW } from '@next-insurance/ng-core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { first, tap } from 'rxjs/operators';

import { AppUrl } from '../core/models/app-url.enum';
import { BusinessInformationAssortmentEligibilityResponse } from '../core/models/business-information-assortment-eligibility-response.model';
import { BusinessInformationAssortmentFlowType } from '../core/models/business-information-assortment-flow-type.enum';
import { CobSegment } from '../core/models/cob-segment.enum';
import { BusinessInformationAssortmentDataService } from '../core/services/business-information-assortment.data.service';
import { SurvicateService } from '../core/services/survicate.service';
import { VerificationV2DataService } from '../core/services/verification-v2.data.service';
import { catchErrorAndLog } from '../shared/utils/catch-error-and-log.utils';
import { AppState } from '../store';
import { BusinessDataService } from './business.data.service';
import { Business } from './models/business.model';
import { BusinessGeneralInfo } from './models/business-general-info.model';
import { BusinessPeopleInfo } from './models/business-people-info.model';
import { BusinessPropertyInfo } from './models/business-property-info.model';
import { businessActions } from './store/business.actions';
import { businessSelectors } from './store/business.selectors';

@Injectable({
  providedIn: 'root',
})
export class BusinessService {
  private store = inject(Store<AppState>);
  private businessDataService = inject(BusinessDataService);
  private survicateService = inject(SurvicateService);
  private verificationV2DataService = inject(VerificationV2DataService);
  private businessInformationAssortmentDataService = inject(BusinessInformationAssortmentDataService);
  private window = inject(WINDOW);
  private router = inject(Router);

  loadBusiness(): Observable<Business> {
    this.store.dispatch(businessActions.setIsLoadingBusiness());

    return this.businessDataService.getBusiness().pipe(
      tap((businessDetails: Business) => {
        this.survicateService.updateTraitsByBusiness(businessDetails);
        if (this.window.NI_NAMESPACE) {
          this.window.NI_NAMESPACE.email = businessDetails.emailAddress;
          this.window.NI_NAMESPACE.businessId = this.getBusinessId();
        }
        this.store.dispatch(businessActions.setBusiness({ businessDetails }));
      }),
      catchErrorAndLog(() => {
        this.router.navigateByUrl(AppUrl.Error);
        return of(null);
      }),
    );
  }

  loadGeneralInfo(): Observable<BusinessGeneralInfo> {
    this.store.dispatch(businessActions.setGeneralInfo({ generalInfo: null }));

    return this.businessDataService.getGeneralInfo().pipe(
      tap((generalInfo: BusinessGeneralInfo) => {
        this.store.dispatch(businessActions.setGeneralInfo({ generalInfo }));
      }),
    );
  }

  loadPeopleInfo(): Observable<BusinessPeopleInfo> {
    return this.businessDataService.getPeopleInfo().pipe(
      tap((peopleInfo: BusinessPeopleInfo) => {
        this.store.dispatch(businessActions.setPeopleInfo({ peopleInfo }));
      }),
    );
  }

  loadPropertyInfo(): Observable<BusinessPropertyInfo> {
    return this.businessDataService.getPropertyInfo().pipe(
      tap((propertyInfo: BusinessPropertyInfo) => {
        this.store.dispatch(businessActions.setPropertyInfo({ propertyInfo }));
      }),
    );
  }

  loadIsEmailVerified(): Observable<boolean> {
    const emailAddress = this.getEmailAddress();

    return this.verificationV2DataService.isVerified(emailAddress).pipe(
      tap((isEmailVerified: boolean) => {
        this.store.dispatch(businessActions.setIsEmailVerified({ isEmailVerified }));
      }),
    );
  }

  loadIsPhoneVerified(): Observable<boolean> {
    const phoneNumber = this.getPhoneNumber();

    return this.verificationV2DataService.isVerified(phoneNumber).pipe(
      tap((isPhoneVerified: boolean) => {
        this.store.dispatch(businessActions.setIsPhoneVerified({ isPhoneVerified }));
      }),
    );
  }

  loadIsEligibleForBusinessInformationAssortment(
    flowType: BusinessInformationAssortmentFlowType,
  ): Observable<BusinessInformationAssortmentEligibilityResponse> {
    return this.businessInformationAssortmentDataService.isEligibleForBusinessInformationAssortment(flowType).pipe(
      catchErrorAndLog(() => {
        return of({ eligible: false });
      }),
      tap((response: BusinessInformationAssortmentEligibilityResponse) => {
        this.store.dispatch(
          businessActions.setIsEligibleForBusinessInformationAssortment({
            isEligible: response.eligible,
            businessInformationAssortmentFlowType: flowType,
          }),
        );
      }),
    );
  }

  loadSegmentByCob(cobId: number): Observable<CobSegment> {
    this.store.dispatch(businessActions.setLoadingCobSegment({ isLoadingCobSegment: true }));

    return this.businessDataService.getSegmentByCob(cobId).pipe(
      catchErrorAndLog(() => of(null)),
      tap((cobSegment: CobSegment) => {
        this.store.dispatch(businessActions.setCobSegment({ cobSegment }));
      }),
    );
  }

  private getBusinessId(): string {
    let businessId: string;
    this.store
      .select(businessSelectors.getBusinessId)
      .pipe(first())
      .subscribe((id: string) => {
        businessId = id;
      });
    return businessId;
  }

  private getEmailAddress(): string {
    let emailAddress: string;
    this.store
      .select(businessSelectors.getEmailAddress)
      .pipe(first())
      .subscribe((email: string) => {
        emailAddress = email;
      });
    return emailAddress;
  }

  private getPhoneNumber(): string {
    let phoneNumber: string;
    this.store
      .select(businessSelectors.getPhoneNumber)
      .pipe(first())
      .subscribe((phone: string) => {
        phoneNumber = phone;
      });
    return phoneNumber;
  }
}
