import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, first, map, withLatestFrom } from 'rxjs/operators';

import { FeatureFlags } from '../../core/models/feature-flags.enum';
import { QueryParams } from '../../core/models/query-params.enum';
import { UserType } from '../../core/models/user-type.enum';
import { CommonConfigDataService } from '../../core/services/common-config.data.service';
import { DynamicDialogService } from '../../core/services/dynamic-dialog.service';
import { FeatureFlagsService } from '../../core/services/feature-flags.service';
import { MobileAppService } from '../../core/services/mobile-app.service';
import { coreSelectors } from '../../core/store/core.selectors';
import { SESSION_STORAGE } from '../../core/tokens/session-storage.token';
import { CoverageCheckupService } from '../../coverage-checkup/services/coverage-checkup.service';
import { catchErrorAndLog } from '../../shared/utils/catch-error-and-log.utils';
import { AppState } from '../../store';
import { selectQueryParam } from '../../store/router.store';
import { OnboardingModalComponent } from '../components/onboarding-modal/onboarding-modal.component';
import { OnboardingStatusResponse } from '../models/onboarding-status-response.model';
import { OnboardingStep } from '../models/onboarding-step.enum';
import { OnboardingDataService } from './onboarding.data.service';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  readonly disableOnboardingStorageKey = 'disableOnboarding';
  private readonly excludedUrls = CommonConfigDataService.getExcludedUrlsForPopup();
  private readonly NAVIGATING_FOR_QUERY_PARAM_UPDATE_FLAG = 'navigatingForQueryParamUpdate';

  constructor(
    private onboardingDataService: OnboardingDataService,
    private dynamicDialogService: DynamicDialogService,
    private store: Store<AppState>,
    private featureFlagsService: FeatureFlagsService,
    private mobileAppService: MobileAppService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private coverageCheckupService: CoverageCheckupService,
    @Inject(SESSION_STORAGE) private sessionStorage: Storage,
  ) {
    this.listenToRouteChanges();
  }

  private _isUserLandedWithOperation: boolean;

  get isUserLandedWithOperation(): boolean {
    return this._isUserLandedWithOperation;
  }

  removeOnboardingVisibleQueryParam(): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { [QueryParams.OnboardingVisible]: null },
      queryParamsHandling: 'merge',
      state: {
        [this.NAVIGATING_FOR_QUERY_PARAM_UPDATE_FLAG]: true,
      },
    });
  }

  private listenToRouteChanges(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart || event instanceof NavigationEnd),
        withLatestFrom(this.store.select(selectQueryParam(QueryParams.Operation))),
      )
      .subscribe(([routerEvent, operation]) => {
        if (routerEvent instanceof NavigationStart) {
          if (!this.isUrlSupported(routerEvent.url) && this.isOnboardingModalOpened()) {
            this.closeOnboardingModal();
          }
        } else if (
          routerEvent instanceof NavigationEnd &&
          routerEvent.urlAfterRedirects !== '/error' &&
          !this.isUserLandedWithOperation &&
          !this.isNavigatingForQueryParamUpdate()
        )
          this.handleRouteChange(routerEvent.url);

        if (operation) {
          this._isUserLandedWithOperation = true;
        }
      });
  }

  private handleRouteChange(url: string): void {
    if (this.isOnboardingEnabled(url)) {
      this.getOnboardingSteps().subscribe((remainingSteps: OnboardingStep[]) => {
        if (remainingSteps?.length) {
          this.dynamicDialogService.open(OnboardingModalComponent, {
            showHeader: false,
            dismissableMask: false,
            closeOnEscape: false,
            data: { remainingSteps },
          });
          this.addOnboardingVisibleQueryParam();
        } else {
          this.coverageCheckupService.loadAndOpenCoverageCheckupModalIfEligible();
        }
      });
    } else {
      this.coverageCheckupService.loadAndOpenCoverageCheckupModalIfEligible();
    }
  }

  isUrlSupported(url: string): boolean {
    return !this.excludedUrls.some((excludedUrlPattern) => url.match(excludedUrlPattern));
  }

  disableOnboardingForTheSession(): void {
    this.sessionStorage.setItem(this.disableOnboardingStorageKey, '1');
  }

  completeOnboarding(): Observable<void> {
    this.disableOnboardingForTheSession();
    return this.onboardingDataService.completeOnboarding();
  }

  private isNavigatingForQueryParamUpdate(): boolean {
    const currentNavigationState = this.router.getCurrentNavigation().extras?.state;
    return currentNavigationState && currentNavigationState[this.NAVIGATING_FOR_QUERY_PARAM_UPDATE_FLAG];
  }

  private isOnboardingModalOpened(): boolean {
    return this.dynamicDialogService.openedDialogs.has(OnboardingModalComponent.name);
  }

  private closeOnboardingModal(): void {
    this.dynamicDialogService.close(OnboardingModalComponent);
  }

  private addOnboardingVisibleQueryParam(): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { [QueryParams.OnboardingVisible]: true },
      queryParamsHandling: 'merge',
      state: {
        [this.NAVIGATING_FOR_QUERY_PARAM_UPDATE_FLAG]: true,
      },
    });
  }

  private isOnboardingEnabled(url: string): boolean {
    return (
      this.featureFlagsService.isActive(FeatureFlags.Onboarding) &&
      !this.mobileAppService.isMobileAppWebview() &&
      !this.isOnboardingDisabledForTheSession() &&
      this.isCustomer() &&
      this.isUrlSupported(url)
    );
  }

  private getOnboardingSteps(): Observable<OnboardingStep[]> {
    return this.onboardingDataService.getOnboardingStatus().pipe(
      withLatestFrom(this.store.select(coreSelectors.getIsAfterPurchase)),
      map(([onboardingStatus, isAfterPurchase]: [OnboardingStatusResponse, boolean]) => {
        if (!onboardingStatus) {
          return null;
        }
        const shouldShowMobileAppStep = onboardingStatus.remainingSteps.includes(OnboardingStep.Final);

        let remainingSteps = shouldShowMobileAppStep
          ? this.getStepsWithMobileApp(onboardingStatus.remainingSteps)
          : onboardingStatus.remainingSteps;

        remainingSteps = isAfterPurchase ? remainingSteps : this.removeStep(OnboardingStep.AmazonSellerInformation, remainingSteps);

        return remainingSteps.includes(OnboardingStep.Final) && remainingSteps.length === 1 ? [] : remainingSteps;
      }),
      catchErrorAndLog(() => {
        return of(null);
      }),
    );
  }

  private getStepsWithMobileApp(steps: OnboardingStep[]): OnboardingStep[] {
    return steps.includes(OnboardingStep.AmazonSellerInformation)
      ? [...steps.slice(0, -2), OnboardingStep.MobileApp, OnboardingStep.AmazonSellerInformation, OnboardingStep.Final]
      : [...steps.slice(0, -1), OnboardingStep.MobileApp, OnboardingStep.Final];
  }

  private removeStep(stepToRemove: OnboardingStep, steps: OnboardingStep[]): OnboardingStep[] {
    return steps.filter((step: OnboardingStep) => step !== stepToRemove);
  }

  private isOnboardingDisabledForTheSession(): boolean {
    return !!this.sessionStorage.getItem(this.disableOnboardingStorageKey);
  }

  private isCustomer(): boolean {
    let isCustomer: boolean;
    this.store
      .select(coreSelectors.getUserType)
      .pipe(withLatestFrom(this.store.select(coreSelectors.isUserLoggedIn)), first())
      .subscribe(([userType, isUserLoggedIn]: [UserType, boolean]) => {
        isCustomer = userType === UserType.Customer && isUserLoggedIn;
      });
    return isCustomer;
  }
}
