import { CommonModule, NgForOf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NiRadioButtonRbModule } from '@next-insurance/ni-material';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { paymentMethodIconsConfig } from '../../configs/payment-method-icons.config';
import { PaymentMethodType } from '../../models/payment-method-details.model';

@Component({
  selector: 'ni-payment-method-type-buttons',
  templateUrl: './payment-method-type-buttons.component.html',
  styleUrls: ['./payment-method-type-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NiRadioButtonRbModule, FormsModule, TranslateModule, NgForOf, CommonModule],
})
export class PaymentMethodTypeButtonsComponent implements OnInit {
  private readonly deviceDetectorService: DeviceDetectorService = inject(DeviceDetectorService);
  protected readonly paymentMethodIconsConfig = paymentMethodIconsConfig;
  @Input({ required: true }) methodTypes: PaymentMethodType[];
  @Input({ required: true }) isAchRecommended: boolean;
  @Output() methodTypeSelected: EventEmitter<PaymentMethodType> = new EventEmitter<PaymentMethodType>();
  paymentMethodSelection: PaymentMethodType;
  isMobile: boolean;

  ngOnInit(): void {
    this.isMobile = this.deviceDetectorService.isMobile();
    this.paymentMethodSelection = this.methodTypes[0];
  }

  onMethodTypeChanged(): void {
    this.methodTypeSelected.emit(this.paymentMethodSelection);
  }

  protected readonly PaymentMethodType = PaymentMethodType;
}
