import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ButtonType } from '@next-insurance/ni-material';
import { UsStateName } from '@next-insurance/utils';
import { Store } from '@ngrx/store';
import { combineLatest, forkJoin, Subscription } from 'rxjs';
import { filter, first, switchMap, tap } from 'rxjs/operators';

import { businessSelectors } from '../../../business/store/business.selectors';
import { CrossSellSuggestion } from '../../../cross-sell/models/cross-sell-suggestion.model';
import { CrossSellService } from '../../../cross-sell/services/cross-sell.service';
import { CrossSellTrackingService } from '../../../cross-sell/services/cross-sell-tracking.service';
import { crossSellSelectors } from '../../../cross-sell/store/cross-sell.selectors';
import { AppState } from '../../../store';
import { CrossSellOfferThrough } from '../../enums/cross-sell-offer-through.enum';
import { FullstoryEvent } from '../../models/fullstory-event.enum';
import { FullStoryService } from '../../services/fullstory.service';
import { WcComplianceBannerService } from '../../services/wc-compliance-banner.service';

@Component({
  selector: 'ni-wc-compliance-banner',
  templateUrl: './wc-compliance-banner.component.html',
  styleUrls: ['./wc-compliance-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WcComplianceBannerComponent implements OnInit, OnDestroy {
  ButtonType = ButtonType;
  hidden = true;
  stateName: UsStateName;
  private showBannerSub = Subscription.EMPTY;
  private businessId: string;
  private shouldOfferThrough: CrossSellOfferThrough;

  constructor(
    private crossSellTrackingService: CrossSellTrackingService,
    private changeDetectorRef: ChangeDetectorRef,
    private store: Store<AppState>,
    private wcComplianceBannerService: WcComplianceBannerService,
    private fullStoryService: FullStoryService,
    private crossSellService: CrossSellService,
  ) {}

  ngOnInit(): void {
    this.initBusinessIdSync();
    this.crossSellService.loadWCSuggestion().subscribe();
    this.handleBannerVisibility();
  }

  private initBusinessIdSync(): void {
    // The subscription is sync because businessId is populated at the businessIdGuard
    this.store
      .select(businessSelectors.getBusinessId)
      .pipe(first())
      .subscribe((businessId) => {
        this.businessId = businessId;
      });
  }

  private handleBannerVisibility(): void {
    this.showBannerSub = combineLatest([
      this.wcComplianceBannerService.isBannerEnabled(this.businessId),
      this.store.select(crossSellSelectors.getWCSuggestion),
      this.store.select(businessSelectors.getStateName),
    ])
      .pipe(
        filter(([isEnabled]: [boolean, CrossSellSuggestion, UsStateName]) => {
          return isEnabled;
        }),
        tap(([, suggestion, stateName]: [boolean, CrossSellSuggestion, UsStateName]) => {
          this.stateName = stateName;
          this.handleSuggestion(suggestion);
        }),
      )
      .subscribe();
  }

  private handleSuggestion(suggestion: CrossSellSuggestion): void {
    this.shouldOfferThrough = suggestion?.shouldOfferThrough;
    if (suggestion?.shouldSuggest) {
      this.setBannerVisibility(true);
    } else {
      this.setBannerVisibility(false);
    }
  }

  private setBannerVisibility(isVisible: boolean): void {
    if (isVisible) {
      this.crossSellTrackingService.trackWCComplianceBannerViewed();
    }

    this.hidden = !isVisible;
    this.changeDetectorRef.markForCheck();
  }

  closeBanner(): void {
    this.crossSellTrackingService.trackWCComplianceBannerCloseButtonClicked();
    this.wcComplianceBannerService.disableBannerForTheSession(this.businessId);
  }

  alreadyInsuredDeclarationClicked(): void {
    this.crossSellTrackingService.trackWCComplianceBannerAlreadyInsuredDeclarationClicked();
    this.wcComplianceBannerService
      .markIsAlreadyInsuredDeclaration()
      .pipe(
        switchMap(() => {
          return forkJoin([this.crossSellService.loadCrossSell(), this.crossSellService.loadWCSuggestion()]);
        }),
      )
      .subscribe();
    this.setBannerVisibility(false);
  }

  getQuote(): void {
    this.fullStoryService.fireEvent(FullstoryEvent.GetWCQuoteFromBanner, {
      shouldOfferThrough: this.shouldOfferThrough,
    });
    this.crossSellTrackingService.trackWCComplianceBannerGetQuoteClicked();
    this.wcComplianceBannerService.startCrossSell(this.shouldOfferThrough);
    this.setBannerVisibility(false);
  }

  ngOnDestroy(): void {
    this.showBannerSub.unsubscribe();
  }
}
