import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { BusinessService } from '../../../../../business/business.service';
import { businessSelectors } from '../../../../../business/store/business.selectors';
import { ZendeskIssueTag } from '../../../../../core/models/zendesk-issue-tag.enum';
import { CommonConfigDataService } from '../../../../../core/services/common-config.data.service';
import { FeatureFlagsService } from '../../../../../core/services/feature-flags.service';
import { VerificationDataService } from '../../../../../core/services/verification.data.service';
import { VerificationV2DataService } from '../../../../../core/services/verification-v2.data.service';
import { ZendeskService } from '../../../../../core/services/zendesk.service';
import { SESSION_STORAGE } from '../../../../../core/tokens/session-storage.token';
import { AppState } from '../../../../../store';
import { OnboardingStep } from '../../../../models/onboarding-step.enum';
import { OnboardingDataService } from '../../../../services/onboarding.data.service';
import { OnboardingTrackingService } from '../../../../services/onboarding-tracking.service';
import { OnboardingVerificationStepComponent } from '../onboarding-verification-step.component';

@Component({
  selector: 'ni-onboarding-phone-verification-step',
  templateUrl: './onboarding-phone-verification-step.component.html',
  styleUrls: ['../onboarding-verification-step.component.scss', '../../onboarding-step/onboarding-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingPhoneVerificationStepComponent extends OnboardingVerificationStepComponent implements OnInit {
  readonly verificationCodeSentStorageKey = 'onboardingVerificationCodeSentToPhone';
  readonly stepSettingsInitialHeader = 'VERIFICATION.PHONE.INITIAL.HEADER';
  readonly stepSettingsCompletedHeader = 'VERIFICATION.COMPLETED.HEADER';
  readonly resendSuccessMessage = 'VERIFICATION.RESEND_CODE_SUCCESS.PHONE';

  constructor(
    protected changeDetectorRef: ChangeDetectorRef,
    protected commonConfigDataService: CommonConfigDataService,
    protected fb: UntypedFormBuilder,
    protected verificationDataService: VerificationDataService,
    protected verificationV2DataService: VerificationV2DataService,
    protected store: Store<AppState>,
    protected featureFlagsService: FeatureFlagsService,
    protected router: Router,
    @Inject(SESSION_STORAGE) protected sessionStorage: Storage,
    private onboardingTrackingService: OnboardingTrackingService,
    private onboardingDataService: OnboardingDataService,
    private businessService: BusinessService,
    private zendeskService: ZendeskService,
  ) {
    super(
      changeDetectorRef,
      commonConfigDataService,
      fb,
      verificationDataService,
      verificationV2DataService,
      store,
      featureFlagsService,
      sessionStorage,
      router,
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  getCurrIdentityInput$(): Observable<string> {
    return this.store.select(businessSelectors.getPhoneNumber);
  }

  trackVerificationEditClicked(): void {
    this.onboardingTrackingService.trackPhoneVerificationEditPhoneClicked();
  }

  trackVerificationLanding(): void {
    this.onboardingTrackingService.trackPhoneVerificationLanding();
  }

  trackVerificationResendCodeClicked(): void {
    this.onboardingTrackingService.trackPhoneVerificationResendCodeClicked();
  }

  trackVerificationResendCodeResult(isSuccess: boolean, niStatusCode: string | undefined): void {
    this.onboardingTrackingService.trackPhoneVerificationResendCodeResult(isSuccess, niStatusCode);
  }

  trackVerificationSendCodeResult(isSuccess: boolean, niStatusCode: string | undefined): void {
    this.onboardingTrackingService.trackPhoneVerificationSendCodeResult(isSuccess, niStatusCode);
  }

  trackVerificationValidateCodeResult(isSuccess: boolean, niStatusCode: string | undefined): void {
    this.onboardingTrackingService.trackPhoneVerificationValidateCodeResult(isSuccess, niStatusCode);
  }

  skipStep(): void {
    this.onboardingTrackingService.trackPhoneVerificationSkippedClicked();
    this.onboardingDataService.skipStep(OnboardingStep.PhoneVerification).subscribe();
    this.moveToNextStep.emit();
  }

  loadIsIdentityVerified(): void {
    this.businessService.loadIsPhoneVerified().subscribe();
  }

  openZendeskChat(): void {
    this.zendeskService.openZendeskChat(undefined, [ZendeskIssueTag.ChatPhoneVerificationIssue]);
  }
}
