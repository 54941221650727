import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

import { NiCurrencyPipe } from '../../../shared/pipes/currency.pipe';
import { PaymentBreakdown } from './payment-breakdown.model';

@Component({
  selector: 'ni-payment-breakdown-modal',
  templateUrl: './payment-breakdown-modal.component.html',
  styleUrls: ['./payment-breakdown-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NiCurrencyPipe, TranslateModule],
})
export class PaymentBreakdownModalComponent {
  dynamicDialogConfig = inject(DynamicDialogConfig<{ paymentBreakdowns: PaymentBreakdown[] }>);
  paymentBreakdowns: PaymentBreakdown[] = this.dynamicDialogConfig.data.paymentBreakdowns;
  totalMonthlyPayment: number = this.dynamicDialogConfig.data.totalPayment;
  paymentPeriod = this.dynamicDialogConfig.data.paymentPeriodTranslation;
}
