import { createAction, props } from '@ngrx/store';

import { Follower } from '../../../followers/models/follower.model';
import { Certificate } from '../models/certificate.model';
import { CertificateAllowedModifiers } from '../models/certificate-allowed-modifiers.model';
import { CertificateAllowedOperations } from '../models/certificate-allowed-operations.model';
import { CertificateHolderRequirementPartialInformation } from '../models/certificate-holder-requirement-partial-information.model';
import { CertificateHolderRequirements } from '../models/certificate-holder-requirements.model';
import { CreateCertificateRequest } from '../models/create-certificate-request.model';
import { CustomCertificateFlowType } from '../models/custom-certificate-flow-type.enum';
import { ThirdPartyPendingRequest } from '../models/third-party-pending-request.model';

export const certificatesActions = {
  setCustomCertificates: createAction(
    '[Certificates] Set custom certificates',
    props<{
      customCertificates: Certificate[];
    }>(),
  ),
  setCertificateHolderRequirementsPartialInformation: createAction(
    '[Certificates] Set listed certificate holder requirements',
    props<{ data: CertificateHolderRequirementPartialInformation[] }>(),
  ),
  setCertificateHolderRequirements: createAction(
    '[Certificates] Set certificate holder requirements',
    props<{
      data: CertificateHolderRequirements;
    }>(),
  ),
  setPublicCertificateAllowedOperations: createAction(
    '[Certificates] Set public certificate allowed operations',
    props<{ allowedOperations: CertificateAllowedOperations }>(),
  ),
  setCertificateAllowedModifiers: createAction(
    '[Certificates] Set custom certificate allowed modifiers',
    props<{ allowedModifiers: CertificateAllowedModifiers }>(),
  ),
  setCreateCertificateRequest: createAction(
    '[Certificates] Set create certificate request',
    props<{ request: CreateCertificateRequest }>(),
  ),
  setPreQuestionsAnswer: createAction('[Certificates] Set pre questions answer', props<{ answer: string }>()),
  cleanCustomCertificateState: createAction('[Certificates] Clean custom certificate state'),
  setIsWaiverChangeFlow: createAction('[Certificates] Set is waiver change flow'),
  setCustomCertificateFlowType: createAction(
    '[Certificates] Set Custom Certificate Flow Type',
    props<{
      flowType: CustomCertificateFlowType;
    }>(),
  ),
  setThirdPartyPendingRequests: createAction(
    '[Certificates] Set third party pending requests',
    props<{ thirdPartyPendingRequests: ThirdPartyPendingRequest[] }>(),
  ),
  setFollowers: createAction('[Certificates] Set followers', props<{ followers: Follower[] }>()),
  addNewFollower: createAction('[Certificates] Add new follower', props<{ follower: Follower }>()),
  removeFollower: createAction('[Certificates] Remove follower', props<{ follower: Follower }>()),
  setCertificateRequest: createAction(
    '[Certificates] Set certificate request',
    props<{
      request: CreateCertificateRequest;
    }>(),
  ),
};
