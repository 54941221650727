import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { LOB } from '@next-insurance/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { CrossSellOrigin } from '../../core/models/cross-sell-origin.enum';
import { MobileAppCustomizedEvent, MobileAppEventId } from '../../core/models/mobile-app-send-event.model';
import { QueryParams } from '../../core/models/query-params.enum';
import { MobileAppEventsService } from '../../core/services/mobile-app-events.service';
import { CrossSellService } from '../../cross-sell/services/cross-sell.service';
import { CrossSellTrackingService } from '../../cross-sell/services/cross-sell-tracking.service';
import { catchErrorAndLog } from '../../shared/utils/catch-error-and-log.utils';

const notifyMobileTechnicalError = (mobileAppEventsService: MobileAppEventsService, translateService: TranslateService): void => {
  const technicalErrorEvent: MobileAppCustomizedEvent = {
    eventId: MobileAppEventId.TechnicalError,
    errorMessage: translateService.instant('GENERAL.ERRORS.TOAST.HEADER'),
    closeWebview: true,
  };
  mobileAppEventsService.notifyMobile(technicalErrorEvent);
};

export const crossSellMobileGuard: CanActivateFn = (route: ActivatedRouteSnapshot): Observable<boolean> => {
  const crossSellService = inject(CrossSellService);
  const crossSellTrackingService = inject(CrossSellTrackingService);
  const mobileAppEventsService = inject(MobileAppEventsService);
  const translateService = inject(TranslateService);

  const lob = route.queryParams[QueryParams.Lob]?.toUpperCase();
  crossSellTrackingService.trackCrossSellFromWebViewLanding(lob);

  if (!lob || !Object.values(LOB).includes(lob)) {
    notifyMobileTechnicalError(mobileAppEventsService, translateService);
    return of(false);
  }

  return crossSellService.getSuggestion(lob).pipe(
    tap(() => crossSellTrackingService.trackCrossSellFromWebViewGetSuggestion(true, lob)),
    map((suggestion) => {
      const { shouldOfferThrough } = suggestion;
      crossSellService.startCrossSell(lob, shouldOfferThrough, CrossSellOrigin.WcBanner);
      return false;
    }),
    catchErrorAndLog((err) => {
      crossSellTrackingService.trackCrossSellFromWebViewGetSuggestion(false, lob, err?.error?.niStatusCode);
      notifyMobileTechnicalError(mobileAppEventsService, translateService);
      return of(false);
    }),
  );
};
