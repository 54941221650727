import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { LOB } from '@next-insurance/core';
import logger from '@next-insurance/logger';
import { UsStateCode } from '@next-insurance/utils';
import { select, Store } from '@ngrx/store';
import { combineLatest, EMPTY, Observable, of } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';

import { businessSelectors } from '../../business/store/business.selectors';
import { CrossSellOfferThrough } from '../../core/enums/cross-sell-offer-through.enum';
import { CobSegment } from '../../core/models/cob-segment.enum';
import { CrossSellOrigin } from '../../core/models/cross-sell-origin.enum';
import { FeatureFlags } from '../../core/models/feature-flags.enum';
import { FeatureFlagsService } from '../../core/services/feature-flags.service';
import { MobileAppService } from '../../core/services/mobile-app.service';
import { NavigationService } from '../../core/services/navigation.service';
import { policiesSelectors } from '../../policies/store/policies.selectors';
import { catchErrorAndLog } from '../../shared/utils/catch-error-and-log.utils';
import { AppState } from '../../store';
import { CrossSellPolicy } from '../models/cross-sell-policy.model';
import { CrossSellPolicyStatus } from '../models/cross-sell-policy-status.enum';
import { CrossSellResponse } from '../models/cross-sell-response.model';
import { CrossSellSuggestion } from '../models/cross-sell-suggestion.model';
import { CrossSellSuggestionResponse } from '../models/cross-sell-suggestion-response.model';
import { crossSellActions } from '../store/cross-sell.actions';
import { CrossSellDataService } from './cross-sell.data.service';
import { CrossSellTrackingService } from './cross-sell-tracking.service';
import { ExternalCrossSellService } from './external-cross-sell.service';
import { FunnelCrossSellService } from './funnel-cross-sell.service';

@Injectable({
  providedIn: 'root',
})
export class CrossSellService {
  readonly monopolisticStates: UsStateCode[] = ['OH', 'ND', 'WA', 'WY'];
  readonly distributorCobId = 910073;

  private store = inject(Store<AppState>);
  private navigationService = inject(NavigationService);
  private crossSellDataService = inject(CrossSellDataService);
  private externalCrossSellService = inject(ExternalCrossSellService);
  private mobileAppService = inject(MobileAppService);
  private featureFlagsService = inject(FeatureFlagsService);
  private trackingService = inject(CrossSellTrackingService);
  private funnelCrossSellService = inject(FunnelCrossSellService);

  loadCrossSell(): Observable<void> {
    this.store.dispatch(crossSellActions.setIsLoading({ isLoading: true }));
    this.store.dispatch(crossSellActions.clearCrossSell());

    return combineLatest([
      this.crossSellDataService.getCrossSell(),
      this.store.select(businessSelectors.isLoadingCobSegment),
      this.store.select(businessSelectors.getCobSegment),
      this.store.select(policiesSelectors.getCOBId),
    ]).pipe(
      filter(([, isLoadingCobSegment, ,]) => !isLoadingCobSegment),
      map(([crossSellResponse, , cobSegment, cobId]: [CrossSellResponse, boolean, CobSegment, number]) => {
        const eligiblePolicies = this.filterOutNotEligibleCrossSellPoliciesByCobSegment(
          crossSellResponse.crossSellPolicies,
          cobSegment,
          cobId,
        );

        this.store.dispatch(
          crossSellActions.setCrossSell({
            crossSellResponse: {
              ...crossSellResponse,
              crossSellPolicies: eligiblePolicies,
            },
          }),
        );
      }),
      first(),
      catchErrorAndLog((error) => {
        this.trackingService.trackCrossSellLoadPoliciesError('ERROR', error);
        this.store.dispatch(
          crossSellActions.setCrossSell({
            crossSellResponse: { crossSellPolicies: [], eligibleForCoverageCheckup: false },
          }),
        );
        return of(null);
      }),
    );
  }

  startCrossSell(lob: LOB, shouldOfferThrough?: CrossSellOfferThrough, crossSellOrigin: CrossSellOrigin = CrossSellOrigin.CrossSell): void {
    const externalCrossSellLink = this.externalCrossSellService.getLink(lob, crossSellOrigin);
    if (shouldOfferThrough === CrossSellOfferThrough.Partner && externalCrossSellLink) {
      if (crossSellOrigin === CrossSellOrigin.WcBanner && this.mobileAppService.isMobileAppWebview()) {
        this.navigationService.navigateTo(externalCrossSellLink, true, true);
      } else {
        this.navigationService.navigateTo(externalCrossSellLink, true);
      }
    } else {
      this.funnelCrossSellService.navigateToFunnelCrossSell(lob, shouldOfferThrough === CrossSellOfferThrough.SmartAgent);
    }
  }

  isPolicyRequiredToBePurchasedFromState(policy: CrossSellPolicy, stateCode: string): boolean {
    return policy.lob === LOB.WC && this.monopolisticStates.includes(stateCode as UsStateCode);
  }

  private filterOutNotEligibleCrossSellPoliciesByCobSegment(
    policies: CrossSellPolicy[],
    cobSegment: CobSegment,
    cobId: number,
  ): CrossSellPolicy[] {
    if (!cobSegment) {
      logger.error(`filterOutNotEligibleCrossSellPoliciesByCobSegment() - cobSegment is missing`);
    }

    return policies.filter((policy) => {
      if (policy.lob === LOB.IM) {
        return this.isEligibleForIM(cobSegment, cobId);
      }
      if (policy.lob === LOB.PL) {
        return this.isEligibleForPL(cobSegment);
      }
      return true;
    });
  }

  getSuggestion(lob: LOB): Observable<CrossSellSuggestion> {
    return this.crossSellDataService.getSuggestion(lob).pipe(
      map((suggestion: CrossSellSuggestionResponse) => {
        let offerThrough = CrossSellOfferThrough.Partner;
        if (suggestion.shouldSellViaNext) {
          offerThrough = CrossSellOfferThrough.Next;
        }

        if (suggestion.crossSellLob.status === CrossSellPolicyStatus.ExternalLobAvailable) {
          offerThrough = CrossSellOfferThrough.SmartAgent;
        }

        return {
          lob: suggestion.lob,
          shouldSuggest: suggestion.shouldSuggest,
          shouldOfferThrough: offerThrough,
        };
      }),
    );
  }

  isHealthCrossSellVisible(): boolean {
    let isHealthCrossSellVisible: boolean;
    this.store.pipe(select(businessSelectors.getBusiness), first()).subscribe((business) => {
      isHealthCrossSellVisible =
        this.featureFlagsService.isActive(FeatureFlags.HealthInsuranceCrossSell) && !business.isBusinessLinkedToExternalAgent;
    });
    return isHealthCrossSellVisible;
  }

  private isEligibleForIM(cobSegment: CobSegment, cobId: number): boolean {
    return cobSegment === CobSegment.Construction || cobSegment === CobSegment.Cleaning || cobId === this.distributorCobId;
  }

  private isEligibleForPL(cobSegment: CobSegment): boolean {
    const ineligibleSegments: CobSegment[] = [CobSegment.Consulting, CobSegment.Construction, CobSegment.Cleaning];

    return !ineligibleSegments.includes(cobSegment);
  }

  loadWCSuggestion(): Observable<CrossSellSuggestion> {
    return this.getSuggestion(LOB.WC).pipe(
      tap((wcSuggestion) => {
        this.trackingService.trackWCComplianceBannerGetSuggestion(true);
        this.store.dispatch(crossSellActions.setWCSuggestion({ wcSuggestion }));
      }),
      catchErrorAndLog((error: HttpErrorResponse) => {
        this.trackingService.trackWCComplianceBannerGetSuggestion(false, error?.error?.niStatusCode);
        this.store.dispatch(crossSellActions.setWCSuggestion({ wcSuggestion: null }));
        return EMPTY;
      }),
    );
  }

  modifyNotifyStatus(lob: LOB, status: boolean): Observable<boolean> {
    return this.crossSellDataService.setNotifyStatus(lob, status).pipe(
      tap(() => {
        this.store.dispatch(
          crossSellActions.setNotifyStatus({
            lob,
            status,
          }),
        );
      }),
    );
  }
}
