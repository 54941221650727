<div class="banner-container" *ngIf="!hidden" [@fadeInAndOut]>
  <div class="banner-content">
    <img src="assets/img/warning.svg" alt="" />
    <div class="banner-body">
      <ng-content select="[banner-body]"></ng-content>
    </div>
    <div class="banner-actions">
      <ng-content select="[banner-actions]"></ng-content>
    </div>
    <div *ngIf="closable" class="close-button" data-test="alert-banner-close-button" (click)="onClose()">
      <img src="assets/img/x-black-icon.svg" class="close-icon" alt="close" />
    </div>
  </div>
</div>
