import { NIUsStateHelper, UsStateName } from '@next-insurance/utils';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { memoize } from 'lodash-es';

import { Address } from '../../core/models/address.model';
import { BusinessInformationAssortmentFlowType } from '../../core/models/business-information-assortment-flow-type.enum';
import { CobSegment } from '../../core/models/cob-segment.enum';
import { AppState } from '../../store';
import { AddressChangeValidationResult } from '../models/address-change-validation-result.model';
import { AffiliateData } from '../models/affiliate-data.model';
import { Business } from '../models/business.model';
import { BusinessGeneralInfo } from '../models/business-general-info.model';
import { BusinessPeopleInfo } from '../models/business-people-info.model';
import { BusinessPropertyInfo } from '../models/business-property-info.model';
import { ContactInfoPersonalDetails } from '../models/contact-info-personal-details.model';
import { BusinessState } from './business.reducer';

const businessState = (state: AppState): BusinessState => state.business;

const isLoading: MemoizedSelector<AppState, boolean> = createSelector(businessState, (state) => state.loading);

const getBusiness: MemoizedSelector<AppState, Business> = createSelector(businessState, (state) => state.businessDetails);

const getBusinessId: MemoizedSelector<AppState, string> = createSelector(getBusiness, (business) => business && business.businessId);

const getBusinessName: MemoizedSelector<AppState, string> = createSelector(getBusiness, (business) => business.businessName);

const getStateName: MemoizedSelector<AppState, UsStateName> = createSelector(getBusiness, (business) =>
  NIUsStateHelper.mapToUsStateName(business.state),
);

const getPhoneNumber: MemoizedSelector<AppState, string> = createSelector(getBusiness, (business) => business.phoneNumber);

const getEmailAddress: MemoizedSelector<AppState, string> = createSelector(getBusiness, (business) => business.emailAddress);

const getAddress: MemoizedSelector<AppState, Address> = createSelector(getBusiness, (business) => ({
  streetAddress: business.street,
  city: business.city,
  stateCode: business.state,
  zipCode: business.zipCode,
}));

const getPersonalDetails: MemoizedSelector<AppState, ContactInfoPersonalDetails> = createSelector(
  getBusiness,
  (business) =>
    business && {
      userFirstName: business.userFirstName,
      userLastName: business.userLastName,
      phoneNumber: business.phoneNumber,
      emailAddress: business.emailAddress,
    },
);

const getIsPhoneVerified: MemoizedSelector<AppState, boolean> = createSelector(businessState, (state) => state.isPhoneVerified);

const getIsEmailVerified: MemoizedSelector<AppState, boolean> = createSelector(businessState, (state) => state.isEmailVerified);

const getFullUserName: MemoizedSelector<AppState, string> = createSelector(
  getBusiness,
  (business) => business && `${business.userFirstName} ${business.userLastName}`,
);

const getGeneralInfo: MemoizedSelector<AppState, BusinessGeneralInfo> = createSelector(businessState, (state) => state.generalInfo);

const getPeopleInfo: MemoizedSelector<AppState, BusinessPeopleInfo> = createSelector(businessState, (state) => state.peopleInfo);

const getPropertyInfo: MemoizedSelector<AppState, BusinessPropertyInfo> = createSelector(businessState, (state) => state.propertyInfo);

const getAddressChangeValidationResults: MemoizedSelector<AppState, AddressChangeValidationResult[]> = createSelector(
  businessState,
  (state) => (state?.addressChangeValidationResult ? Object.values(state.addressChangeValidationResult) : null),
);

const getAddressChangeValidationResultsMap: MemoizedSelector<AppState, Record<string, AddressChangeValidationResult>> = createSelector(
  businessState,
  (state) => state.addressChangeValidationResult,
);

const getCobSegment: MemoizedSelector<AppState, CobSegment> = createSelector(businessState, (business) => business.cobSegment);

const isLoadingCobSegment: MemoizedSelector<AppState, boolean> = createSelector(businessState, (business) => business.isLoadingCobSegment);

const isBusinessLinkedToExternalAgent: MemoizedSelector<AppState, boolean> = createSelector(
  businessState,
  (business) => business.businessDetails.isBusinessLinkedToExternalAgent,
);

const getBusinessAffiliateData: MemoizedSelector<AppState, AffiliateData> = createSelector(
  businessState,
  (business) => business.businessDetails?.affiliateData,
);

const getBusinessInformationAssortmentEligibility = memoize(
  (flowType: BusinessInformationAssortmentFlowType): MemoizedSelector<AppState, boolean> =>
    createSelector(businessState, (business: BusinessState) =>
      business.isEligibleForBusinessInformationAssortment ? business.isEligibleForBusinessInformationAssortment[flowType] : null,
    ),
);

export const businessSelectors = {
  getBusiness,
  getBusinessId,
  isLoading,
  getBusinessName,
  getStateName,
  getIsPhoneVerified,
  getIsEmailVerified,
  getPhoneNumber,
  getEmailAddress,
  getAddress,
  getPersonalDetails,
  getFullUserName,
  getGeneralInfo,
  getPeopleInfo,
  getPropertyInfo,
  getAddressChangeValidationResults,
  getAddressChangeValidationResultsMap,
  getCobSegment,
  isLoadingCobSegment,
  isBusinessLinkedToExternalAgent,
  getBusinessAffiliateData,
  getBusinessInformationAssortmentEligibility,
};
