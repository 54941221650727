import { createAction, props } from '@ngrx/store';

import { CombinedPaymentDetails } from '../models/combined-payment-details.model';

export const paymentActions = {
  setCombinedPaymentDetails: createAction(
    '[Payment] Set combined payment details',
    props<{ combinedPaymentDetails: CombinedPaymentDetails }>(),
  ),
  setIsLoading: createAction('[Payment] Set loading', props<{ isLoading: boolean }>()),
};
