export enum MobileAppReceiveEventId {
  Navigate = 'NAVIGATE_TO',
  OpenChatBot = 'OPEN_CHAT_BOT',
}

export type OpenChatBot = {
  mobileEventId: MobileAppReceiveEventId.OpenChatBot;
};

export type NavigateWithRouter = {
  mobileEventId: MobileAppReceiveEventId.Navigate;
  path: string;
};

export type MobileAppReceivedEvent = OpenChatBot | NavigateWithRouter;
