<div class="title-container">
  @if (agentType === ChatbotAgentType.Claims) {
    <p class="p-md-bold claim-title">{{ 'HELP.POLICY_ACTIONS.FILE_CLAIM.TITLE' | translate }}</p>
  } @else {
    <img class="next-logo" alt="next logo" src="assets/logos/logo.svg" />
  }
  <div class="buttons-wrapper">
    <img
      class="action-button"
      *ngIf="minimizable"
      (click)="minimizeChat()"
      data-test="minimize-icon"
      alt="minimize"
      src="assets/img/chatbot/arrow-down.svg"
    />
    <img class="action-button" (click)="closeChat()" data-test="exit-icon" alt="exit" src="assets/img/chatbot/cross-grey.svg" />
  </div>
</div>
