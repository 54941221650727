import { Action, createReducer, on } from '@ngrx/store';

import { Follower } from '../../../followers/models/follower.model';
import { Certificate } from '../models/certificate.model';
import { CertificateAllowedModifiers } from '../models/certificate-allowed-modifiers.model';
import { CertificateAllowedOperations } from '../models/certificate-allowed-operations.model';
import { CertificateHolderRequirementPartialInformation } from '../models/certificate-holder-requirement-partial-information.model';
import { CertificateHolderRequirements } from '../models/certificate-holder-requirements.model';
import { CreateCertificateFromHolderRequirements } from '../models/create-certificate-from-holder-requirements.model';
import { CreateCertificateRequest, CustomCertificateRequestChanges } from '../models/create-certificate-request.model';
import { CustomCertificateFlowType } from '../models/custom-certificate-flow-type.enum';
import { DesignationsLanguagesResponse } from '../models/designations-languages-response.model';
import { ThirdPartyPendingRequest } from '../models/third-party-pending-request.model';
import { certificatesActions } from './certificates.actions';

export interface CertificatesState {
  customCertificates: Certificate[];
  thirdPartyPendingRequests: ThirdPartyPendingRequest[];
  followers: Follower[];
  preQuestionsAnswer: string;
  customCertificate: {
    flowType?: CustomCertificateFlowType;
    publicCertificateAllowedOperations?: CertificateAllowedOperations;
    allowedModifiers?: CertificateAllowedModifiers;
    descriptionOfOperations?: string;
    createCertificateRequest?: CreateCertificateRequest | CustomCertificateRequestChanges;
    createCertificateFromHolderRequirements?: CreateCertificateFromHolderRequirements;
    isWaiverChangeFlow?: boolean;
    designationsSpecialLanguages?: DesignationsLanguagesResponse;
    certificateHolderRequirementsPartialInformation?: CertificateHolderRequirementPartialInformation[];
    certificateHolderRequirements?: CertificateHolderRequirements;
  };
}

export const certificatesInitialState: CertificatesState = {
  customCertificates: null,
  thirdPartyPendingRequests: null,
  followers: null,
  preQuestionsAnswer: null,
  customCertificate: {},
};

const reducer = createReducer(
  certificatesInitialState,
  on(certificatesActions.setCustomCertificates, (state, action) => ({
    ...state,
    customCertificates: action.customCertificates,
  })),
  on(certificatesActions.setPublicCertificateAllowedOperations, (state, action) => ({
    ...state,
    customCertificate: {
      ...state.customCertificate,
      publicCertificateAllowedOperations: action.allowedOperations,
      isWaiverChangeFlow: false,
    },
  })),
  on(certificatesActions.setCertificateAllowedModifiers, (state, action) => ({
    ...state,
    customCertificate: {
      ...state.customCertificate,
      allowedModifiers: action.allowedModifiers,
      isWaiverChangeFlow: false,
    },
  })),
  on(certificatesActions.setCertificateHolderRequirementsPartialInformation, (state, action) => ({
    ...state,
    customCertificate: {
      ...state.customCertificate,
      certificateHolderRequirementsPartialInformation: action.data,
    },
  })),
  on(certificatesActions.setCertificateHolderRequirements, (state, action) => ({
    ...state,
    customCertificate: {
      ...state.customCertificate,
      certificateHolderRequirements: action.data,
    },
  })),
  on(certificatesActions.setCreateCertificateRequest, (state, action) => ({
    ...state,
    customCertificate: {
      ...state.customCertificate,
      createCertificateRequest: {
        ...state.customCertificate.createCertificateRequest,
        ...action.request,
      },
    },
  })),
  on(certificatesActions.setIsWaiverChangeFlow, (state) => ({
    ...state,
    customCertificate: {
      ...state.customCertificate,
      isWaiverChangeFlow: true,
    },
  })),
  on(certificatesActions.cleanCustomCertificateState, (state) => ({
    ...state,
    customCertificate: certificatesInitialState.customCertificate,
  })),
  on(certificatesActions.setCustomCertificateFlowType, (state, action) => ({
    ...state,
    customCertificate: {
      ...state.customCertificate,
      flowType: action.flowType,
    },
  })),
  on(certificatesActions.setThirdPartyPendingRequests, (state, action) => ({
    ...state,
    thirdPartyPendingRequests: action.thirdPartyPendingRequests,
  })),
  on(certificatesActions.setFollowers, (state, action) => ({
    ...state,
    followers: action.followers,
  })),
  on(certificatesActions.addNewFollower, (state, action) => ({
    ...state,
    followers: [...state.followers, action.follower],
  })),
  on(certificatesActions.removeFollower, (state, action) => ({
    ...state,
    followers: state.followers.filter((follower) => follower.thirdPartyId !== action.follower.thirdPartyId),
  })),
  on(certificatesActions.setCertificateRequest, (state, action) => ({
    ...state,
    customCertificate: {
      ...state.customCertificate,
      createCertificateRequest: action.request,
    },
  })),
  on(certificatesActions.setPreQuestionsAnswer, (state, action) => ({
    ...state,
    preQuestionsAnswer: action.answer,
  })),
);

export function certificatesReducer(state: CertificatesState, action: Action): CertificatesState {
  return reducer(state, action);
}
