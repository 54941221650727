import { inject, Injectable } from '@angular/core';
import { LOB } from '@next-insurance/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY } from 'rxjs';
import { first, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { MobileAppCustomizedEvent, MobileAppEventId } from '../../core/models/mobile-app-send-event.model';
import { UserType } from '../../core/models/user-type.enum';
import { MobileAppService } from '../../core/services/mobile-app.service';
import { MobileAppEventsService } from '../../core/services/mobile-app-events.service';
import { NavigationService } from '../../core/services/navigation.service';
import { coreActions } from '../../core/store/core.actions';
import { coreSelectors } from '../../core/store/core.selectors';
import { CrossSellTokenResponse } from '../../home/models/cross-sell-token-response.model';
import { CrossSellTokenStatus } from '../../home/models/cross-sell-token-status.enum';
import { ToastType } from '../../shared/components/toast/models/toast-type.enum';
import { toastActions } from '../../shared/components/toast/store/toast.actions';
import { catchErrorAndLog } from '../../shared/utils/catch-error-and-log.utils';
import { AppState } from '../../store';
import { CrossSellDataService } from './cross-sell.data.service';
import { CrossSellTrackingService } from './cross-sell-tracking.service';

@Injectable({
  providedIn: 'root',
})
export class FunnelCrossSellService {
  private mobileAppEventsService = inject(MobileAppEventsService);
  private translateService = inject(TranslateService);
  private crossSellDataService = inject(CrossSellDataService);
  private navigationService = inject(NavigationService);
  private mobileAppService = inject(MobileAppService);
  private trackingService = inject(CrossSellTrackingService);
  private store = inject(Store<AppState>);

  navigateToFunnelCrossSell(lob: LOB, shouldOfferThroughSmartAgent: boolean): void {
    this.store.dispatch(coreActions.setLoading({ isLoading: true }));
    this.crossSellDataService
      .getRetrieveOpportunityToken()
      .pipe(
        tap((res: CrossSellTokenResponse) => {
          if (res.status === CrossSellTokenStatus.Success) {
            this.goToApplication(lob, res.crossSellToken, shouldOfferThroughSmartAgent);
          } else {
            this.failedNavigatingFunnel(res.status, 'failed to get ROT');
          }
        }),
        catchErrorAndLog((errorMessage: any) => {
          this.failedNavigatingFunnel('ERROR', errorMessage);
          return EMPTY;
        }),
      )
      .subscribe();
  }

  private goToApplication(lob: LOB, rot: string, shouldOfferThroughSmartAgent: boolean | undefined): void {
    const userType = this.getUserType();
    const crossSellUrl = this.getCrossSellApplicationUrl(rot, lob, userType, shouldOfferThroughSmartAgent);
    this.navigationService.navigateTo(crossSellUrl);
  }

  private getUserType(): UserType {
    let userType: UserType;
    this.store.pipe(select(coreSelectors.getUserType), first()).subscribe((type) => {
      userType = type;
    });
    return userType;
  }

  private getCrossSellApplicationUrl(rot: string, lob: LOB, userType: UserType, shouldOfferThroughSmartAgent = false): string {
    const applicationMode = 'portal_cross_sell';
    let applicationUrl = `${environment.trackingUrl}/links?serial=99013&rot=${rot}&application_mode=${applicationMode}&user_type=${userType}`;
    if (lob) {
      applicationUrl += `&coverages=["${lob}"]`;
    }
    if (shouldOfferThroughSmartAgent) {
      applicationUrl += `&direct_smart_agent_cross_sell=true`;
    }
    return applicationUrl;
  }

  private failedNavigatingFunnel(status: string, error: string): void {
    this.trackingService.trackCrossSellRedirectError(status, error);
    if (this.mobileAppService.isMobileAppWebview()) {
      let customizedEvent: MobileAppCustomizedEvent = {
        eventId: MobileAppEventId.TechnicalError,
        errorMessage: this.translateService.instant('GENERAL.ERRORS.TOAST.HEADER'),
        closeWebview: true,
      };
      this.mobileAppEventsService.notifyMobile(customizedEvent);
      return;
    }

    this.store.dispatch(coreActions.setLoading({ isLoading: false }));
    this.store.dispatch(
      toastActions.showToast({
        toastType: ToastType.Error,
        message: 'CROSS_SELL.REDIRECT_TO_APPLICATION.ERROR.TEXT',
        title: 'CROSS_SELL.REDIRECT_TO_APPLICATION.ERROR.TITLE',
      }),
    );
  }
}
