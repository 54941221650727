import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { environment } from '../../../environments/environment';
import { NavigationService } from './navigation.service';
import { UserAgentService } from './user-agent.service';

@Injectable({
  providedIn: 'root',
})
export class MobileAppService {
  private readonly webviewLandingPagesPatterns = [
    /\/certificates\/create-certificate$/,
    /\/certificates\/followers/,
    /\/certificates\/third-party-requests\/notifications/,
    /\/certificates\/third-party-requests\/changes/,
    /\/certificates\/live-certificate/,
    /\/coverage\//,
  ];

  constructor(
    private cookieService: CookieService,
    private userAgentService: UserAgentService,
    private navigationService: NavigationService,
  ) {}

  isMobileAppWebview(): boolean {
    return this.cookieService.get(environment.mobileAppWebviewCookieName) === 'true';
  }

  isDownloadAppEnabled(): boolean {
    return this.userAgentService.isAndroid() || this.userAgentService.isIos();
  }

  downloadApp(): void {
    this.navigationService.navigateTo('https://nextinsurance.page.link/wT16');
  }

  isVersionGreaterOrEqual(mobileVersion: string): boolean {
    return (
      this.cookieService
        .get(environment.mobileAppVersionCookieName)
        .localeCompare(mobileVersion, undefined, { numeric: true, sensitivity: 'base' }) >= 0
    );
  }

  canEditContactInfo(): boolean {
    return this.isMobileAppWebview() && this.isVersionGreaterOrEqual('1.0.4');
  }

  isWebviewLandingPage(path: string): boolean {
    return this.webviewLandingPagesPatterns.some((landingPagePattern) => path.match(landingPagePattern));
  }
}
