<div class="risk-review-step-container ni-dialog-content">
  <div class="h4_h3-regular" data-test="risk-review-title">{{ 'COVERAGE_CHECKUP.RISK_REVIEW.TITLE' | translate }}</div>
  <div class="p-sm_md sub-title" data-test="risk-review-sub-title"> {{ 'COVERAGE_CHECKUP.RISK_REVIEW.SUB_TITLE' | translate }}</div>
  <div
    class="risk-review-container"
    data-test="risk-review-coverage-checkup-details"
    *ngIf="coverageCheckupDetails$ | async as coverageCheckupDetails"
  >
    <div class="gauge-and-bullets-container">
      <div class="gauge-container">
        <ni-gauge [riskLevelInput]="coverageCheckupDetails.coverageRiskLevel"></ni-gauge>
        <div class="p-sm-bold gauge-text" data-test="risk-review-gauge-text">{{
          'COVERAGE_CHECKUP.TITLE.' + coverageCheckupDetails.coverageRiskLevel.toUpperCase()
            | translate: { cobName: coverageCheckupDetails.cobName }
        }}</div>
      </div>
      <div class="bullets-container">
        <div
          class="bullet"
          data-test="risk-review-bullet-container"
          *ngFor="let lobCoverageDetails of coverageCheckupDetails.lobsCoverageDetails | orderLobsCoverageDetails"
        >
          <img
            [attr.data-test]="'risk-review-bullet-icon-' + lobCoverageDetails.lob"
            [src]="
              lobCoverageDetails.required && !lobCoverageDetails.alreadyCovered
                ? 'assets/img/alert-orange-icon.svg'
                : 'assets/img/white-check-mark-inside-green-circle.svg'
            "
          />
          <span class="p-sm bullet-text" [attr.data-test]="'risk-review-bullet-text-' + lobCoverageDetails.lob">
            {{
              'COVERAGE_CHECKUP.RISK_REVIEW.' +
                (lobCoverageDetails.alreadyCovered ? 'COVERED.' : lobCoverageDetails.required ? 'EXPOSED.' : 'NO_RISK.') +
                lobCoverageDetails.lob | translate
            }}</span
          >
        </div>
      </div>
    </div>
    <div class="out-of-date p-sm_md"
      >{{ 'COVERAGE_CHECKUP.OUT_OF_DATE' | translate }}
      <ni-button-rb
        [buttonType]="ButtonType.Text"
        class="out-of-date-cta"
        data-test="risk-review-update-business-info-button"
        (click)="updateBusinessInfo()"
        >{{ 'COVERAGE_CHECKUP.UPDATE_INFO' | translate }}</ni-button-rb
      ></div
    >
  </div>

  <ng-container *ngIf="criticalCoveragesCrossSell$ | async as criticalCoveragesCrossSell">
    <ng-container *ngIf="criticalCoveragesCrossSell.length">
      <div class="p-sm-bold" data-test="risk-review-cross-sell-title">{{
        'COVERAGE_CHECKUP.RISK_REVIEW.CROSS_SELL_TITLE' | translate
      }}</div>
      <div class="cross-sell-container">
        <ni-cross-sell-policies [showTitle]="false" [policies]="criticalCoveragesCrossSell"></ni-cross-sell-policies>
      </div>
    </ng-container>
  </ng-container>

  <div class="p-sm legal-disclaimer" data-test="risk-review-legal-disclaimer">
    {{ 'COVERAGE_CHECKUP.RISK_REVIEW.FOOTER' | translate }}
  </div>
</div>
