import { PackageType } from '@next-insurance/core';
import { Action, createReducer, on } from '@ngrx/store';

import { PackagesDetails } from '../models/packages.model';
import { coveragesActions } from './coverages.actions';

export interface CoveragesState {
  packagesDetails: PackagesDetails;
  selectedPackageType: PackageType;
  isLoading: boolean;
  isLoadingFailed: boolean;
}

export const coveragesInitialState: CoveragesState = {
  packagesDetails: null,
  selectedPackageType: null,
  isLoading: false,
  isLoadingFailed: false,
};

const reducer = createReducer(
  coveragesInitialState,
  on(coveragesActions.setPackagesForPolicyLoading, (state: CoveragesState, action) => ({
    ...state,
    isLoading: action.isLoading,
  })),
  on(coveragesActions.setPackagesForPolicy, (state: CoveragesState, action) => ({
    ...state,
    isLoading: false,
    packagesDetails: action.packagesDetails,
  })),
  on(coveragesActions.clearPackagesForPolicy, (state: CoveragesState) => ({
    ...state,
    packagesDetails: null as PackagesDetails,
  })),
  on(coveragesActions.loadPackagesForPolicyFailed, (state: CoveragesState) => ({
    ...state,
    isLoading: false,
    isLoadingFailed: true,
  })),
  on(coveragesActions.setCurrentSelectedPackage, (state: CoveragesState, action) => ({
    ...state,
    selectedPackageType: action.selectedPackageType,
  })),
  on(coveragesActions.setSelectedPackageAsCurrent, (state: CoveragesState) => {
    const packagesDetails = Object.keys(state.packagesDetails).reduce(
      (all: PackagesDetails, currPackageType: PackageType) => ({
        ...all,
        [currPackageType]: {
          ...state.packagesDetails[currPackageType],
          isCurrentPackage: state.selectedPackageType === currPackageType,
        },
      }),
      {},
    );
    return {
      ...state,
      packagesDetails,
    };
  }),
);

export function coveragesReducer(state: CoveragesState, action: Action): CoveragesState {
  return reducer(state, action);
}
