<div class="payment-breakdown-container">
  @for (breakdown of paymentBreakdowns; track breakdown) {
    <div class="payment-details-wrapper">
      <div class="payment-details-title">
        <div class="p-sm_md">{{ breakdown.title | translate }}</div>
        <div class="p-sm_md">{{ breakdown.price | niCurrency: '1.2-2' }}</div>
      </div>
      @if (breakdown.subtitle) {
        <div class="payment-details-subtitle p-sm_md">
          <span>{{ breakdown.subtitle | translate }}</span>
          @if (breakdown.action) {
            <a (click)="breakdown.action.onClick()" [attr.data-test]="'subtitle-{{breakdown.action.label}}'">
              {{ breakdown.action.label | translate }}
            </a>
          }
        </div>
      }
    </div>
  }
  <div class="payment-summary-wrapper">
    <div class="p-md-bold">{{ 'PAYMENT.PAYMENT_BREAKDOWN.TOTAL_PAYMENT' | translate: { paymentPeriod } }}</div>
    <div class="p-sm_md">{{ totalMonthlyPayment | niCurrency: '1.2-2' }}</div>
  </div>
</div>
