import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

import { BusinessService } from '../../business/business.service';
import { businessSelectors } from '../../business/store/business.selectors';
import { PermittedChangesService } from '../../business-change/services/permitted-changes.service';
import { PaymentService } from '../../payment/payment.service';
import { PoliciesService } from '../../policies/services/policies.service';
import { policiesSelectors } from '../../policies/store/policies.selectors';
import { catchErrorAndLog } from '../../shared/utils/catch-error-and-log.utils';
import { AppState } from '../../store';
import { AppUrl } from '../models/app-url.enum';
import { coreActions } from '../store/core.actions';
import { coreSelectors } from '../store/core.selectors';

const waitForBusiness = (store: Store<AppState>): Observable<boolean> => {
  return store.pipe(
    select(businessSelectors.isLoading),
    filter((isLoading: boolean) => !isLoading),
    first(),
  );
};

const waitForPolicies = (store: Store<AppState>): Observable<boolean> => {
  return store.pipe(
    select(policiesSelectors.isLoading),
    filter((isLoading: boolean) => !isLoading),
    first(),
  );
};

const getIsUserLoggedIn = (store: Store<AppState>): boolean => {
  let isUserLoggedIn = false;
  store
    .select(coreSelectors.isUserLoggedIn)
    .pipe(
      first(),
      map((isLoggedIn: boolean) => {
        isUserLoggedIn = isLoggedIn;
      }),
    )
    .subscribe();
  return isUserLoggedIn;
};

const loadBusiness = (router: Router, businessService: BusinessService): void => {
  businessService
    .loadBusiness()
    .pipe(
      catchErrorAndLog(() => {
        router.navigateByUrl(AppUrl.Error);
        return EMPTY;
      }),
    )
    .subscribe();
};

export const businessGuard: CanActivateFn = (): Observable<boolean> => {
  const store = inject(Store<AppState>);
  const router = inject(Router);
  const permittedChangesService = inject(PermittedChangesService);
  const paymentService = inject(PaymentService);
  const businessService = inject(BusinessService);
  const policiesService = inject(PoliciesService);

  if (getIsUserLoggedIn(store)) {
    store.dispatch(coreActions.setLoading({ isLoading: true }));
    policiesService.loadPolicies(true).subscribe();
    loadBusiness(router, businessService);
    permittedChangesService.loadPermittedChanges().subscribe();
    paymentService.loadCombinedPaymentDetails().subscribe();

    return forkJoin([waitForBusiness(store), waitForPolicies(store)]).pipe(map(() => true));
  }

  return of(true);
};
