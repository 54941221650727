import { PackageData } from '@next-insurance/core';
import { Action, createReducer, on } from '@ngrx/store';

import { PoliciesInfoResponse } from '../models/policies-info-response.model';
import { policiesActions } from './policies.actions';

export interface PoliciesState {
  policiesInfo: PoliciesInfoResponse;
  packageData: { [policyId: number]: PackageData };
  isLoading: boolean;
}

export const policiesInitialState: PoliciesState = {
  policiesInfo: {
    policies: [],
    hasPartnerPolicies: false,
  },
  packageData: {},
  isLoading: false,
};

const reducer = createReducer(
  policiesInitialState,
  on(policiesActions.setIsLoadingPolicies, (state, action) => ({
    ...state,
    isLoading: action.isLoading,
  })),
  on(policiesActions.setPolicies, (state, action) => ({
    ...state,
    policiesInfo: action.policiesInfo,
    isLoading: false,
  })),
  on(policiesActions.setPackageData, (state, action) => ({
    ...state,
    packageData: action.policiesPackageData,
  })),
);

export function policiesReducer(state: PoliciesState, action: Action): PoliciesState {
  return reducer(state, action);
}
