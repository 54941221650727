import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PackageType } from '@next-insurance/core';
import { toDate } from '@next-insurance/date';
import logger from '@next-insurance/logger';
import { Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { first, map, mergeMap, tap } from 'rxjs/operators';

import { BusinessService } from '../../business/business.service';
import { AppUrl } from '../../core/models/app-url.enum';
import { BusinessStatus } from '../../core/models/business-status.enum';
import { DocLinkResponse } from '../../core/models/doc-link-response.model';
import { MobileAppCustomizedEvent, MobileAppEventId } from '../../core/models/mobile-app-send-event.model';
import { PackageDataPerPolicy } from '../../core/models/package-data.model';
import { FileDownloadService } from '../../core/services/file-download.service';
import { MobileAppEventsService } from '../../core/services/mobile-app-events.service';
import { PortalAuthService } from '../../core/services/portal-auth.service';
import { SurvicateService } from '../../core/services/survicate.service';
import { coreSelectors } from '../../core/store/core.selectors';
import { catchErrorAndLog } from '../../shared/utils/catch-error-and-log.utils';
import { AppState } from '../../store';
import { PoliciesInfoResponse } from '../models/policies-info-response.model';
import { Policy } from '../models/policy.model';
import { PolicyFutureChangeType } from '../models/policy-future-change-type.enum';
import { PolicyStatus } from '../models/policy-status.enum';
import { policiesActions } from '../store/policies.actions';
import { policiesSelectors } from '../store/policies.selectors';
import { PoliciesDataService } from './policies.data.service';

@Injectable({
  providedIn: 'root',
})
export class PoliciesService {
  private mapTriaPackageTypes: Partial<Record<PackageType, PackageType>> = {
    [PackageType.BasicTria]: PackageType.Basic,
    [PackageType.ProTria]: PackageType.Pro,
    [PackageType.ProPlusTria]: PackageType.ProPlus,
  };

  constructor(
    private fileDownloadService: FileDownloadService,
    private store: Store<AppState>,
    private policiesDataService: PoliciesDataService,
    private portalAuthService: PortalAuthService,
    private businessService: BusinessService,
    private survicateService: SurvicateService,
    private mobileAppEventsService: MobileAppEventsService,
    private router: Router,
  ) {}

  loadPolicies(preventMobileBusinessChangeEvent = false): Observable<PoliciesInfoResponse> {
    this.store.dispatch(policiesActions.setIsLoadingPolicies({ isLoading: true }));

    return this.policiesDataService.getPoliciesInfo().pipe(
      tap((policiesInfo: PoliciesInfoResponse) => {
        if (policiesInfo.policies.length) {
          this.businessService.loadSegmentByCob(policiesInfo.policies[0]?.cobId).subscribe();
          this.survicateService.updateTraitsByPolicies(policiesInfo.policies);
          this.notifyMobileBusinessStatusUpdate(policiesInfo.policies, preventMobileBusinessChangeEvent);

          this.store.dispatch(policiesActions.setPolicies({ policiesInfo }));
        } else {
          logger.error('no policies found for user');
          this.portalAuthService.logout();
        }
      }),
      catchErrorAndLog(() => {
        this.router.navigateByUrl(AppUrl.Error);
        return of(null);
      }),
    );
  }

  static isHistoricalUser(policies: Policy[]): boolean {
    return policies.every((policy) => ![PolicyStatus.Active, PolicyStatus.Bound, PolicyStatus.Paid].includes(policy.policyStatus));
  }

  getPackageTypeWithoutTria(policy: Policy): PackageType {
    const policyPackageType = policy.packageType;
    return this.mapTriaPackageTypes[policyPackageType] || policyPackageType;
  }

  downloadPolicy(policy: Policy): Observable<boolean> {
    return this.policiesDataService.getPolicyDocLink(policy.policyId).pipe(
      map((response: DocLinkResponse) => {
        this.fileDownloadService.openOrDownloadFile(response.url, response.docName);
        return true;
      }),
    );
  }

  downloadPolicies(): Observable<boolean> {
    return this.store.select(policiesSelectors.getPolicies).pipe(
      first(),
      mergeMap((policies: Policy[]) => {
        return forkJoin(policies.map((policy) => this.policiesDataService.getPolicyDocLink(policy.policyId))).pipe(
          map((docLinkResponses: DocLinkResponse[]) => {
            docLinkResponses.forEach((docLinkResponse: DocLinkResponse) => {
              this.fileDownloadService.openOrDownloadFile(docLinkResponse.url, docLinkResponse.docName);
            });
            return true;
          }),
        );
      }),
    );
  }

  getFutureChangeDate(policy: Policy): Date {
    if (policy.changeTypeToScheduleDate[PolicyFutureChangeType.CarrierPayrollChange]) {
      const cancellationDate = toDate(policy.changeTypeToScheduleDate[PolicyFutureChangeType.CancellationChange]);
      const payrollChangeDate = toDate(policy.changeTypeToScheduleDate[PolicyFutureChangeType.CarrierPayrollChange]);
      if (policy.changeTypeToScheduleDate[PolicyFutureChangeType.CancellationChange] && cancellationDate < payrollChangeDate) {
        return null;
      }
      return payrollChangeDate;
    }
    return null;
  }

  loadPackageData(): Observable<PackageDataPerPolicy> {
    return this.policiesDataService.getPackageData().pipe(
      tap((policiesPackageData: PackageDataPerPolicy) => {
        this.store.dispatch(policiesActions.setPackageData({ policiesPackageData }));
      }),
      catchErrorAndLog(() => {
        this.router.navigateByUrl(AppUrl.Error);
        return of(null);
      }),
    );
  }

  private notifyMobileBusinessStatusUpdate(policies: Policy[], preventMobileBusinessChangeEvent: boolean): void {
    const isHistoricalUser = PoliciesService.isHistoricalUser(policies);
    const isAfterPurchase = this.getIsAfterPurchase();

    if (!preventMobileBusinessChangeEvent || isAfterPurchase) {
      // optimization for mobile - prevent reload the policies if unnecessary.
      this.mobileAppEventsService.notifyMobile({
        eventId: MobileAppEventId.BusinessStatusUpdate,
        closeWebview: false,
        businessStatus: isHistoricalUser ? BusinessStatus.Historical : BusinessStatus.Active,
      } as MobileAppCustomizedEvent);
    }
  }

  private getIsAfterPurchase(): boolean {
    let isAfterPurchase: boolean;
    this.store
      .select(coreSelectors.getIsAfterPurchase)
      .pipe(first())
      .subscribe((isUserAfterPurchase: boolean) => {
        isAfterPurchase = isUserAfterPurchase;
      });
    return isAfterPurchase;
  }
}
