<div *ngIf="!isMobileAppWebview" [niHolidayTheme]="HolidayThemeElement.Header" class="header" data-test="header">
  <div class="top">
    <div class="logo-container" data-test="logo-container">
      <a (click)="goToHomePage()" [niHolidayTheme]="HolidayThemeElement.Logo" class="logo" data-test="ni-logo"></a>
    </div>
    <div *ngIf="isNavigationTabsVisible" class="navigation-container" data-test="navigation-container">
      <ni-portal-navigation data-test="portal-navigation"></ni-portal-navigation>
      <div class="right-container p-md" data-test="right-icons">
        <ni-referral-program [placement]="'header'" [icon]="true" *niWideDesktop="true" />
        <ni-language-switch (onLanguageChanged)="languageChanged($event)" *niWideDesktop="true"></ni-language-switch>
        <div (click)="goToHelpCenter()" class="help-link" data-test="help-button" translate="HEADER.HELP"></div>
        <a (click)="logout()" *niWideDesktop="true" class="logout-link" data-test="logout-link">{{ 'HEADER.LOGOUT' | translate }}</a>
      </div>
    </div>
    <div *ngIf="!isNavigationTabsVisible" class="navigation-container right-content" data-test="login-navigation-container">
      <ni-language-switch (onLanguageChanged)="languageChanged($event)"></ni-language-switch>
    </div>
  </div>
</div>
<div>
  <ni-unsupported-browsers-banner class="alert-banner" data-test="unsupported-browsers-banner"></ni-unsupported-browsers-banner>
  <ng-container *ngIf="isAlertBannersVisible">
    <ni-urgent-user-message-banner
      *ngIf="isUrgentUserMessageEnabled"
      class="alert-banner"
      data-test="urgent-user-message-banner"
    ></ni-urgent-user-message-banner>
    <ni-failed-payment-banner class="alert-banner" data-test="failed-payment-banner"></ni-failed-payment-banner>
    <ni-not-proprietary-policies-banner
      class="alert-banner"
      data-test="not-proprietary-policies-banner"
    ></ni-not-proprietary-policies-banner>
    <ni-wc-compliance-banner class="alert-banner" data-test="wc-compliance-banner"></ni-wc-compliance-banner>
  </ng-container>
</div>
