<div class="radio-buttons-container">
  <ni-radio-button-rb
    *ngFor="let methodType of methodTypes"
    [name]="methodType"
    [value]="methodType"
    [label]="'PAYMENT.RADIO_BUTTONS' + (isMobile ? '.MOBILE.' : '.') + methodType | translate"
    [(ngModel)]="paymentMethodSelection"
    (change)="onMethodTypeChanged()"
    [icon]="$any(paymentMethodIconsConfig)[methodType]"
    [hideRadioBullet]="true"
    [attr.data-test]="'payment-method-type-radio-button-' + methodType"
    [isPaymentMethodSource]="true"
  >
    <div *ngIf="methodType === PaymentMethodType.BankAccount && isAchRecommended" class="status-green right-content">{{
      'PAYMENT.RADIO_BUTTONS.RECOMMENDED' | translate
    }}</div>
  </ni-radio-button-rb>
</div>
