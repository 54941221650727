import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ChatService } from '@next-insurance/ni-chat';

import { InteractionType } from '../models/interaction-type.enum';
import { MobileAppReceivedEvent, MobileAppReceiveEventId } from '../models/mobile-app-receive-event.model';
import { MobileAppCustomizedEvent, MobileAppEventId } from '../models/mobile-app-send-event.model';
import { REACT_NATIVE_WEBVIEW } from '../tokens/react-native-webview.token';
import { MobileAppService } from './mobile-app.service';
import { NavigationService } from './navigation.service';
import { TrackingService } from './tracking.service';
import { UserAgentService } from './user-agent.service';
import { ZendeskService } from './zendesk.service';

@Injectable({
  providedIn: 'root',
})
export class MobileAppEventsService {
  constructor(
    private userAgentService: UserAgentService,
    private navigationService: NavigationService,
    private trackingService: TrackingService,
    private mobileAppService: MobileAppService,
    private router: Router,
    private chatService: ChatService,
    protected zendeskService: ZendeskService,
    @Inject(REACT_NATIVE_WEBVIEW) private reactNativeWebView: any,
  ) {
    if (this.mobileAppService.isMobileAppWebview()) {
      this.initRegistrationToMobileEvents();

      this.chatService.onMinimize$.subscribe(() => {
        this.notifyMobile({ eventId: MobileAppEventId.ChatbotClosed, closeWebview: false });
      });
      this.chatService.onClose$.subscribe(() => {
        this.notifyMobile({ eventId: MobileAppEventId.ChatbotClosed, closeWebview: false });
      });
    }
  }

  private initRegistrationToMobileEvents(): void {
    window.addEventListener('message', this.handleMessageEvent.bind(this));
  }

  private handleMessageEvent(event: MessageEvent<MobileAppReceivedEvent>): void {
    const { data } = event;

    switch (data.mobileEventId) {
      case MobileAppReceiveEventId.Navigate:
        this.chatService.minimize();
        this.router.navigateByUrl(data.path);
        break;
      case MobileAppReceiveEventId.OpenChatBot:
        this.openChat();
        break;
    }
  }

  private openChat(): void {
    if (this.zendeskService.isChatting()) {
      this.zendeskService.continueChat();
    } else {
      this.chatService.open();
    }
  }

  notifyMobile(event: MobileAppCustomizedEvent): void {
    if (this.mobileAppService.isMobileAppWebview()) {
      this.trackingService.track({
        interactionType: InteractionType.Debug,
        placement: 'general',
        name: 'mobile-app-event',
        interactionData: {
          ...event,
        },
      });
      this.navigationService.waitForUserInteractionAndDo(() => this.postMessage(event));
    }
  }

  shouldSendPaymentRefreshEvent(): boolean {
    return this.mobileAppService.isMobileAppWebview() && this.mobileAppService.isVersionGreaterOrEqual('1.1.4');
  }

  isHideLoaderEnabled(): boolean {
    return this.mobileAppService.isVersionGreaterOrEqual('1.0.3') && this.mobileAppService.isMobileAppWebview();
  }

  sendHideLoaderEvent(): void {
    if (this.isHideLoaderEnabled()) {
      this.notifyMobile({ eventId: MobileAppEventId.HideWebViewLoader, closeWebview: false });
    }
  }

  redirectToDownloadApp(): void {
    this.notifyMobile({
      eventId: MobileAppEventId.OpenExternalBrowser,
      url: this.userAgentService.isAndroid()
        ? 'https://play.google.com/store/apps/details?id=com.nextinsurance'
        : 'https://apps.apple.com/us/app/next-insurance/id1580721755',
      closeWebview: true,
    });
  }

  private postMessage(event: MobileAppCustomizedEvent): void {
    this.reactNativeWebView.postMessage(JSON.stringify(event));
  }
}
