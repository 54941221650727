import { createSelector, MemoizedSelector } from '@ngrx/store';

import { AppState } from '../../store';
import { PaymentDetailsPerPolicy } from '../models/payment-details-per-policy.model';
import { PaymentMethodDetails } from '../models/payment-method-details.model';
import { PaymentState } from './payment.reducer';

const paymentState = (state: AppState): PaymentState => state.payment;

const isLoading: MemoizedSelector<AppState, boolean> = createSelector(paymentState, (state) => state.isLoading);
const isLoaded: MemoizedSelector<AppState, boolean> = createSelector(paymentState, (state) => state.isLoaded);

const getPaymentMethodDetails: MemoizedSelector<AppState, PaymentMethodDetails> = createSelector(
  paymentState,
  (state) => state.paymentMethodDetails,
);

const getPaymentDetailsPerPolicy: MemoizedSelector<AppState, PaymentDetailsPerPolicy> = createSelector(
  paymentState,
  (state) => state.paymentDetailsPerPolicy,
);

export const paymentSelectors = {
  isLoaded,
  isLoading,
  getPaymentMethodDetails,
  getPaymentDetailsPerPolicy,
};
