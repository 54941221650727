import { LOB } from '@next-insurance/core';
import { createAction, props } from '@ngrx/store';

import { CrossSellResponse } from '../models/cross-sell-response.model';
import { CrossSellSuggestion } from '../models/cross-sell-suggestion.model';

const setCrossSell = createAction('[Cross sell] Set Cross Sell', props<{ crossSellResponse: CrossSellResponse }>());
const clearCrossSell = createAction('[Cross sell] Clear Cross Sell');
const setIsLoading = createAction('[Cross sell] Set Is Loading', props<{ isLoading: boolean }>());
const setNotifyStatus = createAction('[Cross sell] Set Notify Status', props<{ lob: LOB; status: boolean }>());
const setWCSuggestion = createAction('[Cross sell] Set WC Suggestion', props<{ wcSuggestion: CrossSellSuggestion }>());

export const crossSellActions = {
  setCrossSell,
  setNotifyStatus,
  setWCSuggestion,
  clearCrossSell,
  setIsLoading,
};
