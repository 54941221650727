<div>
  <div class="banner">
    <img src="assets/img/rectangle.svg" class="rectangle" />
    <img src="assets/img/triangle.svg" class="triangle" />
    <span class="banner-text p-md-bold">{{ 'CROSS_SELL.TILE.OPEN_ENROLLMENT' | translate }}</span>
  </div>
  <div class="content">
    <div class="cross-sell-info">
      <div class="lob-name-and-status-container">
        <div class="policy-type h6_h4-regular" data-test="policy-type">
          {{ 'GENERAL.LOB.HI' | translate }}
        </div>
        <div class="status p-sm_md status-gray">
          {{ 'CROSS_SELL.TILE.OFFERED_THROUGH_PARTNER' | translate }}
        </div>
      </div>
      <div class="p-sm_md">
        <span data-test="description">{{ 'CROSS_SELL.DESCRIPTION.HI' | translate }}</span>
        <span class="link" data-test="learn-more-link" (click)="clickLearnMore()"> {{ 'CROSS_SELL.LEARN_MORE' | translate }}</span>
      </div>
    </div>
    <div class="cross-sell-actions">
      <div class="policy-details p-sm_md">
        <div class="add-button-container">
          <ni-button-rb [buttonType]="ButtonType.Secondary" (click)="openHealthInsuranceModal()" data-test="see-options-button">
            <img src="assets/img/link-icon.svg" class="icon-link" alt="external link icon" />
            {{ 'CROSS_SELL.TILE.SEE_OPTIONS' | translate }}
          </ni-button-rb>
        </div>
      </div>
    </div>
  </div>
</div>
