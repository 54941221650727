import { PaymentPlanFees } from '../../payment/models/payment-plan-fees.model';

export const defaultPlanFees: PaymentPlanFees = {
  '98a0b0042f9f5a1be24eebe9e1e813bb': {
    fees: {
      CARD_TRANSACTION_FEE: { percentage: 2.99, isActive: true },
      SERVICE_FEE: { monthlyAmount: 345, yearlyAmount: 452 },
    },
  },
};
