<div class="ni-dialog-title wide-padding center">
  <img alt="alert-icon" src="assets/img/alert-icon.svg" />
  <div class="h6_h4-regular">{{ 'REFRESH_MODAL.TITLE' | translate }}</div>
</div>

<div class="ni-dialog-content center wide-padding p-sm_md">
  {{ 'REFRESH_MODAL.MESSAGE' | translate }}
</div>

<div class="ni-dialog-footer wide-padding">
  <ni-button-rb (click)="refresh()">
    {{ 'REFRESH_MODAL.REFRESH' | translate }}
  </ni-button-rb>
</div>
