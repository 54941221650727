import { Action, createReducer, on } from '@ngrx/store';

import { BusinessInformationAssortmentFlowType } from '../../core/models/business-information-assortment-flow-type.enum';
import { CobSegment } from '../../core/models/cob-segment.enum';
import { AddressChangeValidationResult } from '../models/address-change-validation-result.model';
import { Business } from '../models/business.model';
import { BusinessGeneralInfo } from '../models/business-general-info.model';
import { BusinessPeopleInfo } from '../models/business-people-info.model';
import { BusinessPropertyInfo } from '../models/business-property-info.model';
import { businessActions } from './business.actions';

export interface BusinessState {
  businessDetails: Business;
  loading: boolean;
  isPhoneVerified: boolean;
  isEmailVerified: boolean;
  generalInfo: BusinessGeneralInfo;
  peopleInfo: BusinessPeopleInfo;
  propertyInfo: BusinessPropertyInfo;
  addressChangeValidationResult: Record<string, AddressChangeValidationResult>;
  cobSegment: CobSegment;
  isLoadingCobSegment: boolean;
  isEligibleForBusinessInformationAssortment: Record<BusinessInformationAssortmentFlowType, boolean>;
}

export const businessInitialState: BusinessState = {
  businessDetails: null,
  loading: false,
  isPhoneVerified: null,
  isEmailVerified: null,
  generalInfo: null,
  peopleInfo: null,
  propertyInfo: null,
  addressChangeValidationResult: null,
  cobSegment: CobSegment.Other,
  isLoadingCobSegment: false,
  isEligibleForBusinessInformationAssortment: null,
};

const reducer = createReducer(
  businessInitialState,
  on(businessActions.setIsLoadingBusiness, (state) => ({
    ...state,
    loading: true,
  })),
  on(businessActions.setBusiness, (state, action) => ({
    ...state,
    loading: false,
    businessDetails: {
      ...action.businessDetails,
      businessId: state.businessDetails?.businessId,
    },
  })),
  on(businessActions.setBusinessId, (state, action) => ({
    ...state,
    businessDetails: {
      ...state.businessDetails,
      businessId: action.businessId,
    },
  })),
  on(businessActions.setIsPhoneVerified, (state, action) => ({
    ...state,
    isPhoneVerified: action.isPhoneVerified,
  })),
  on(businessActions.setIsEmailVerified, (state, action) => ({
    ...state,
    isEmailVerified: action.isEmailVerified,
  })),
  on(businessActions.setGeneralInfo, (state, action) => ({
    ...state,
    generalInfo: action.generalInfo,
  })),
  on(businessActions.setPeopleInfo, (state, action) => ({
    ...state,
    peopleInfo: action.peopleInfo,
  })),
  on(businessActions.setPropertyInfo, (state, action) => ({
    ...state,
    propertyInfo: action.propertyInfo,
  })),
  on(businessActions.setAddressChangeValidationResult, (state, action) => {
    const updatedValidationResults = { ...state.addressChangeValidationResult };
    if (action.addressChangeValidationResult) {
      updatedValidationResults[action.addressControlName] = action.addressChangeValidationResult;
    } else {
      delete updatedValidationResults[action.addressControlName];
    }

    return {
      ...state,
      addressChangeValidationResult: {
        ...updatedValidationResults,
      },
    };
  }),
  on(businessActions.clearAddressChangeValidationResult, (state) => ({
    ...state,
    addressChangeValidationResult: null,
  })),
  on(businessActions.setCobSegment, (state, action) => ({
    ...state,
    cobSegment: action.cobSegment,
    isLoadingCobSegment: false,
  })),
  on(businessActions.setLoadingCobSegment, (state, action) => ({
    ...state,
    isLoadingCobSegment: action.isLoadingCobSegment,
  })),
  on(businessActions.setIsEligibleForBusinessInformationAssortment, (state, action) => ({
    ...state,
    isEligibleForBusinessInformationAssortment: {
      ...state.isEligibleForBusinessInformationAssortment,
      [action.businessInformationAssortmentFlowType]: action.isEligible,
    },
  })),
);

export function businessReducer(state: BusinessState, action: Action): BusinessState {
  return reducer(state, action);
}
