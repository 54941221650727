<div class="container" [attr.data-step]="OnboardingStep.PhoneVerification">
  <div class="img-container">
    <img data-test="step-icon" src="/assets/img/calling-phone.svg" alt="calling-phone-img" />
  </div>

  <div class="content-container" *ngIf="!editIdentityView; else editPhone">
    <div class="header h4_h3-regular" data-test="header" [ngClass]="{ 'is-step-completed': isStepCompleted }">
      {{ stepSettings[currentStepStatus].header | translate }}
    </div>
    <ng-container *ngIf="!isStepCompleted">
      <div class="identification h6_h4-regular" data-test="phone-verification-address">
        {{ currIdentityInput$ | async | phone }}
      </div>
      <form [formGroup]="verificationForm">
        <div class="ni-input-label-rb">{{ 'VERIFICATION.VERIFICATION_CODE' | translate }}</div>
        <div
          class="verification-code-input-container ni-input-rb"
          data-test="verification-code-input-container"
          [style.--digits-num]="verificationCodeLength"
          [ngClass]="{ animation: showAnimation, invalid: verificationForm.controls.code.invalid }"
        >
          <input
            class="verification-code-input novalidate"
            data-test="verification-code-input"
            type="tel"
            [maxlength]="verificationCodeLength"
            formControlName="code"
            niTrim
            spellcheck="false"
            (input)="onInputChange()"
            [customErrors]="verificationCodeErrors"
            (paste)="onPaste($event)"
            [readOnly]="isStepCompleted"
          />
        </div>
      </form>
      <div class="actions">
        <ni-button-rb
          class="action-button"
          [buttonType]="ButtonType.Tertiary"
          [disabled]="isSubmitting"
          (click)="skipStep()"
          data-test="skip-step-button"
        >
          {{ 'VERIFICATION.PHONE.INITIAL.SKIP_STEP' | translate }}
        </ni-button-rb>
        <ni-button-rb
          class="action-button"
          [disabled]="isSubmitting"
          [buttonType]="ButtonType.Tertiary"
          (click)="onEditIdentityClicked()"
          data-test="edit-identity-button"
        >
          {{ 'VERIFICATION.PHONE.EDIT.BUTTON' | translate }}
        </ni-button-rb>
      </div>

      <div class="footer p-xs_sm">
        <span> {{ 'VERIFICATION.PHONE.INITIAL.FOOTER.HAVING_TROUBLE' | translate }} </span>
        <a (click)="resendCode()" data-test="resend-code-button">
          {{ 'VERIFICATION.RESEND_CODE' | translate }}
        </a>
        <span>{{ 'VERIFICATION.OR' | translate }} </span>
        <span class="chat-with-us-link" (click)="openZendeskChat()">{{ 'VERIFICATION.CHAT_WITH_US' | translate }}</span>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #editPhone>
  <ni-onboarding-edit-phone
    (finished)="onEditIdentityFinished($event)"
    (abortOnboarding)="abortOnboarding.emit()"
    data-test="edit-phone-view"
    class="edit-identification-view"
  ></ni-onboarding-edit-phone>
</ng-template>
