import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import logger from '@next-insurance/logger';
import { WINDOW } from '@next-insurance/ng-core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, forkJoin, lastValueFrom, NEVER, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { publicPaths } from '../../../../server/config/public-paths.config';
import { businessActions } from '../../business/store/business.actions';
import { AuthErrorStatuses } from '../../login/models/auth-error-status.enum';
import { LoginTrackingService } from '../../login/services/login-tracking.service';
import { AppState } from '../../store';
import { QueryParams } from '../models/query-params.enum';
import { SessionStorageParams } from '../models/session-storage-params.enum';
import { UserType } from '../models/user-type.enum';
import { coreActions } from '../store/core.actions';
import { LOCAL_STORAGE } from '../tokens/local-storage.token';
import { SESSION_STORAGE } from '../tokens/session-storage.token';
import { FeatureFlagsService } from './feature-flags.service';
import { HolidayThemeService } from './holiday-theme.service';
import { PortalAuthService } from './portal-auth.service';

export enum LoginCallbacksPath {
  PostLogin = '/public/next-login/post-login-callback',
  PostLogout = '/public/next-login/post-logout-callback',
  PostProductRedirectLogin = '/public/next-login/post-product-redirect-login',
  ProductRedirect = '/public/product-redirect-login',
  Logout = '/public/logout',
  PostOktaLogin = '/public/okta-login-callback',
}

export const returnUrlStorageKey = 'returnUrlWithQueryParams';

@Injectable({
  providedIn: 'root',
})
export class AppInitializationService {
  private featureFlagsService = inject(FeatureFlagsService);
  private translateService = inject(TranslateService);
  private holidayThemeService = inject(HolidayThemeService);
  private portalAuthService = inject(PortalAuthService);
  private store = inject(Store<AppState>);
  private httpClient = inject(HttpClient);
  private window = inject(WINDOW);
  private loginTrackingService = inject(LoginTrackingService);
  private localStorage: Storage = inject(LOCAL_STORAGE);
  private sessionStorage: Storage = inject(SESSION_STORAGE);

  async initializeApp(): Promise<boolean> {
    if (this.isLoginPath()) {
      await this.handleLoginPath();
      await this.blockAppLoading();
    }

    const isAuthenticated = await this.isAuthenticated();
    if (!isAuthenticated && !this.isPublicPage()) {
      this.saveReturnUrl(this.window.location.pathname);
      await this.portalAuthService.navigateToNextLogin();
      await this.blockAppLoading();
    }

    // we need this param to prevent trackingID replacement during login navigations. after a successful login we should remove it
    this.sessionStorage.removeItem(SessionStorageParams.ShouldKeepTrackingId);
    return this.getInitialData();
  }

  private loadTranslateService(): Observable<any> {
    this.translateService.setDefaultLang('en');
    return this.translateService.use('en');
  }

  private blockAppLoading(): Promise<void> {
    return lastValueFrom(NEVER);
  }

  private saveReturnUrl(returnUrl: string): void {
    const queryParams = new URLSearchParams(this.window.location.search);

    this.checkForMlTokenError(queryParams);

    queryParams.delete(QueryParams.ReturnUrl);

    const returnUrlWithQueryParams = `${returnUrl}${queryParams.size > 0 ? `?${queryParams.toString()}` : ''}`;

    this.localStorage.setItem(returnUrlStorageKey, returnUrlWithQueryParams);
  }

  private isPublicPage(): boolean {
    for (const path of publicPaths) {
      const pathRegex = new RegExp(path);
      if (pathRegex.test(this.window.location.pathname)) {
        return true;
      }
    }

    return false;
  }

  private getInitialData(): Promise<boolean> {
    return lastValueFrom(
      forkJoin([
        this.featureFlagsService.loadFeatureFlags(),
        this.loadTranslateService(),
        this.holidayThemeService.loadHolidayTheme(),
      ]).pipe(
        map(([flagsLoaded, translationsLoaded, themeLoaded]) => {
          return flagsLoaded && translationsLoaded && themeLoaded;
        }),
      ),
    );
  }

  private async isAuthenticated(): Promise<boolean> {
    try {
      const businessId = await firstValueFrom(this.httpClient.get<string>('api/business/id'));
      this.setBusinessId(businessId);
      return true;
    } catch (error: any) {
      if ([401, 403].includes(error.status)) {
        this.setBusinessId('');
      } else {
        throw error;
      }
    }

    return false;
  }

  private setBusinessId(businessId: string): void {
    this.store.dispatch(businessActions.setBusinessId({ businessId }));
    this.store.dispatch(coreActions.setIsUserLoggedIn({ isUserLoggedIn: !!businessId }));
  }

  private redirectToReturnUrl(): void {
    const returnUrl = this.localStorage.getItem(returnUrlStorageKey) || '/home';

    this.localStorage.removeItem(returnUrlStorageKey);

    this.window.location.replace(returnUrl);
  }

  private checkForMlTokenError(queryParams: URLSearchParams): void {
    const mlTokenStatus = queryParams.get(QueryParams.MlTokenStatus) as AuthErrorStatuses;
    if (mlTokenStatus) {
      this.portalAuthService.setLoginErrorInCookie(JSON.stringify({ beStatusErrorCode: mlTokenStatus }));
      queryParams.delete(QueryParams.MlTokenStatus);
    }
  }

  private isLoginPath(): boolean {
    return Object.values(LoginCallbacksPath).includes(this.window.location.pathname as LoginCallbacksPath);
  }

  private async handleLoginPath(): Promise<void> {
    switch (this.window.location.pathname) {
      case LoginCallbacksPath.ProductRedirect:
        await this.portalAuthService.navigateToNextLogin(true);
        break;

      case LoginCallbacksPath.PostProductRedirectLogin:
        await this.portalAuthService.exchangeLoginResponseToTokens(true);
        this.redirectToReturnUrl();
        break;

      case LoginCallbacksPath.PostLogin:
        this.loginTrackingService.trackLandingAfterNextLogin(this.window.location.search);
        await this.portalAuthService.exchangeLoginResponseToTokens();
        this.redirectToReturnUrl();
        break;

      case LoginCallbacksPath.PostOktaLogin:
        // eslint-disable-next-line no-case-declarations
        await this.portalAuthService.handlePostOktaLogin();
        this.handleOktaReturnUrl();
        break;

      case LoginCallbacksPath.PostLogout:
        await this.portalAuthService.navigateToNextLogin();
        break;

      case LoginCallbacksPath.Logout:
        // eslint-disable-next-line no-case-declarations
        const returnUrl = new URLSearchParams(this.window.location.search).get(QueryParams.ReturnUrl) || '/home';
        this.saveReturnUrl(returnUrl);
        this.portalAuthService.logout();
        break;

      default:
        logger.error(`login path not found `, null, { path: this.window.location.pathname });
        this.portalAuthService.logout();
        break;
    }
  }

  private handleOktaReturnUrl(): void {
    const returnUrl = new URLSearchParams(this.window.location.search).get(QueryParams.ReturnUrl) || '/home';
    const newUrl = new URL(returnUrl, this.window.location.origin);
    newUrl.searchParams.set(QueryParams.UserType, UserType.ATeam);
    const newUrlString = newUrl.toString();

    this.window.location.replace(newUrlString);
  }
}
