import { createAction, props } from '@ngrx/store';

import { BusinessInformationAssortmentFlowType } from '../../core/models/business-information-assortment-flow-type.enum';
import { CobSegment } from '../../core/models/cob-segment.enum';
import { AddressChangeValidationResult } from '../models/address-change-validation-result.model';
import { Business } from '../models/business.model';
import { BusinessGeneralInfo } from '../models/business-general-info.model';
import { BusinessPeopleInfo } from '../models/business-people-info.model';
import { BusinessPropertyInfo } from '../models/business-property-info.model';

const setIsLoadingBusiness = createAction('[Business] Set is loading business');

const setBusiness = createAction('[Business] Set business', props<{ businessDetails: Business }>());

const setBusinessId = createAction('[Business] Set business id', props<{ businessId: string }>());

const setIsPhoneVerified = createAction('[Business] Set is user phone is verified', props<{ isPhoneVerified: boolean }>());

const setIsEmailVerified = createAction('[Business] Set is user email is verified', props<{ isEmailVerified: boolean }>());

const setGeneralInfo = createAction('[Business] Set general info', props<{ generalInfo: BusinessGeneralInfo }>());

const setPeopleInfo = createAction('[Business] Set people info', props<{ peopleInfo: BusinessPeopleInfo }>());

const setPropertyInfo = createAction('[Business] Set property info', props<{ propertyInfo: BusinessPropertyInfo }>());

const setAddressChangeValidationResult = createAction(
  '[Business] Set address change validation result',
  props<{ addressChangeValidationResult: AddressChangeValidationResult; addressControlName: string }>(),
);

const clearAddressChangeValidationResult = createAction('[Business] Clear address change validation result');

const setCobSegment = createAction('[Business] Set cob segment', props<{ cobSegment: CobSegment }>());

const setLoadingCobSegment = createAction('[Business] Set loading cob segment', props<{ isLoadingCobSegment: boolean }>());

const setIsEligibleForBusinessInformationAssortment = createAction(
  '[Business] Set Is Eligible For Business Information Assortment',
  props<{ businessInformationAssortmentFlowType: BusinessInformationAssortmentFlowType; isEligible: boolean }>(),
);

export const businessActions = {
  setIsLoadingBusiness,
  setBusiness,
  setBusinessId,
  setIsPhoneVerified,
  setIsEmailVerified,
  setGeneralInfo,
  setPeopleInfo,
  setPropertyInfo,
  setAddressChangeValidationResult,
  clearAddressChangeValidationResult,
  setCobSegment,
  setLoadingCobSegment,
  setIsEligibleForBusinessInformationAssortment,
};
