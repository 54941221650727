import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import { policiesSelectors } from '../../../policies/store/policies.selectors';
import { AppState } from '../../../store';
import { AppUrl } from '../../models/app-url.enum';
import { HelpTip } from '../../models/help-tip.enum';
import { NavigationSubTab, NavigationTab } from '../../models/navigation-tab.enum';
import { Tab } from '../../models/tab.model';

const iconsPath = '/assets/img/nav';

@Component({
  selector: 'ni-portal-navigation',
  templateUrl: './portal-navigation.component.html',
  styleUrls: ['./portal-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalNavigationComponent implements OnInit {
  tabs: Tab[];

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.tabs = this.getTabs();
  }

  private getTabs(): Tab[] {
    const isCertificatesNotAllowed$ = this.store.pipe(
      select(policiesSelectors.hasActiveOrBoundPolicy()),
      withLatestFrom(this.store.select(policiesSelectors.isHistoricalUser)),
      map(([hasActiveOrBoundPolicy, isHistoricalUser]: [boolean, boolean]) => {
        return !hasActiveOrBoundPolicy && !isHistoricalUser;
      }),
    );

    const isAutoCardsNotAllowed$: Observable<boolean> = this.store
      .select(policiesSelectors.isAutoCardsAvailable)
      .pipe(map((isAutoCardsAvailable) => !isAutoCardsAvailable));

    return [
      {
        id: NavigationTab.Certificate,
        displayName: 'HEADER.TABS.CERTIFICATES',
        routerLink: AppUrl.Certificates,
        helpTip: [HelpTip.ManageCertificates, HelpTip.CertificatesGeneral],
        icon: `${iconsPath}/certificate-medal.svg`,
        hidden$: isCertificatesNotAllowed$,
        isTabsHidden$: isAutoCardsNotAllowed$,
        tabs: [
          {
            id: NavigationSubTab.MyCertificates,
            displayName: 'HEADER.TABS.CERTIFICATES',
            routerLink: AppUrl.Certificates,
            hidden$: isCertificatesNotAllowed$,
          },
          {
            id: NavigationSubTab.Auto,
            displayName: 'HEADER.TABS.AUTO',
            hidden$: isAutoCardsNotAllowed$,
            routerLink: AppUrl.CertificatesAutoTab,
          },
        ],
      },
      {
        id: NavigationTab.Coverage,
        displayName: 'HEADER.TABS.COVERAGE',
        routerLink: AppUrl.Coverage,
        helpTip: [HelpTip.CoverageGeneral],
        icon: `${iconsPath}/shield.svg`,
        tabs: [
          {
            id: NavigationSubTab.MyCoverage,
            displayName: 'HEADER.TABS.COVERAGE',
            routerLink: AppUrl.Coverage,
          },
          {
            id: NavigationSubTab.Followers,
            displayName: 'HEADER.TABS.FOLLOWERS',
            routerLink: AppUrl.Followers,
          },
        ],
      },
      {
        id: NavigationTab.Account,
        displayName: 'HEADER.TABS.ACCOUNT',
        routerLink: AppUrl.Business,
        helpTip: [HelpTip.UpdateBusiness, HelpTip.AccountGeneral],
        icon: `${iconsPath}/profile.svg`,
        tabs: [
          {
            id: NavigationSubTab.Business,
            displayName: 'HEADER.TABS.BUSINESS',
            routerLink: AppUrl.Business,
          },
          {
            id: NavigationSubTab.DocumentCenter,
            displayName: 'HEADER.TABS.DOCUMENT_CENTER',
            routerLink: AppUrl.DocumentCenter,
          },
          {
            id: NavigationSubTab.Billing,
            displayName: 'HEADER.TABS.BILLING',
            routerLink: AppUrl.Billing,
          },
        ],
      },
      {
        id: NavigationTab.Claims,
        displayName: 'HEADER.TABS.CLAIMS',
        routerLink: AppUrl.Claims,
        helpTip: [HelpTip.FileClaim, HelpTip.ClaimsGeneral],
        icon: `${iconsPath}/claims.svg`,
      },
    ];
  }
}
