import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ButtonType } from '@next-insurance/ni-material';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';

import { verificationConfig } from '../../../../core/config/verification.config';
import { AppUrl } from '../../../../core/models/app-url.enum';
import { VerificationScope } from '../../../../core/models/verification-scope.model';
import { CommonConfigDataService } from '../../../../core/services/common-config.data.service';
import { FeatureFlagsService } from '../../../../core/services/feature-flags.service';
import { NiValidatorsService } from '../../../../core/services/ni-validators.service';
import { VerificationDataService } from '../../../../core/services/verification.data.service';
import { VerificationV2DataService } from '../../../../core/services/verification-v2.data.service';
import { SESSION_STORAGE } from '../../../../core/tokens/session-storage.token';
import { ToastType } from '../../../../shared/components/toast/models/toast-type.enum';
import { toastActions } from '../../../../shared/components/toast/store/toast.actions';
import { catchErrorAndLog } from '../../../../shared/utils/catch-error-and-log.utils';
import { AppState } from '../../../../store';
import { StepStatus } from '../../../models/step-status.enum';
import { OnboardingStepComponent } from '../onboarding-step/onboarding-step.component';

@Component({
  selector: 'ni-onboarding-verification-step',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class OnboardingVerificationStepComponent extends OnboardingStepComponent implements OnInit {
  verificationCodeLength: number;
  showAnimation: boolean;
  editIdentityView: boolean;
  isSubmitting: boolean;
  ButtonType = ButtonType;
  verificationForm: UntypedFormGroup;
  currIdentityInput$: Observable<string>;
  abstract readonly verificationCodeSentStorageKey: string;
  abstract readonly stepSettingsInitialHeader: string;
  abstract readonly stepSettingsCompletedHeader: string;
  abstract readonly resendSuccessMessage: string;
  readonly verificationCodeErrors = verificationConfig.codeErrors;

  stepSettings: { [status in StepStatus]: { header: string; icon: string } };

  protected constructor(
    protected changeDetectorRef: ChangeDetectorRef,
    protected commonConfigDataService: CommonConfigDataService,
    protected fb: UntypedFormBuilder,
    protected verificationDataService: VerificationDataService,
    protected verificationV2DataService: VerificationV2DataService,
    protected store: Store<AppState>,
    protected featureFlagsService: FeatureFlagsService,
    @Inject(SESSION_STORAGE) protected sessionStorage: Storage,
    protected router: Router,
  ) {
    super(changeDetectorRef);
  }

  get verificationCodeControl(): AbstractControl {
    return this.verificationForm.controls.code;
  }

  get verificationCode(): string {
    return this.verificationCodeControl.value;
  }

  set verificationCode(code: string) {
    this.verificationForm.patchValue({ code });
  }

  abstract trackVerificationValidateCodeResult(isSuccess: boolean, niStatusCode?: string): void;

  abstract trackVerificationSendCodeResult(isSuccess: boolean, niStatusCode?: string): void;

  abstract trackVerificationResendCodeResult(isSuccess: boolean, niStatusCode?: string): void;

  abstract trackVerificationLanding(): void;

  abstract trackVerificationResendCodeClicked(): void;

  abstract trackVerificationEditClicked(): void;

  abstract loadIsIdentityVerified(): void;

  abstract getCurrIdentityInput$(): Observable<string>;

  ngOnInit(): void {
    this.stepSettings = {
      [StepStatus.Initial]: {
        header: this.stepSettingsInitialHeader,
        icon: '/assets/onboarding/phone-verification-step.svg',
      },
      [StepStatus.Completed]: {
        header: this.stepSettingsCompletedHeader,
        icon: '/assets/onboarding/phone-verification-completed.svg',
      },
    };
    this.trackVerificationLanding();
    this.currIdentityInput$ = this.getCurrIdentityInput$().pipe(
      map((identityInput) => {
        let trimmedIdentityInput = identityInput?.trim();
        if (NiValidatorsService.isPhoneValid(identityInput)) {
          trimmedIdentityInput = trimmedIdentityInput.replace(/[\s()-]/g, '');
        }
        return trimmedIdentityInput;
      }),
    );
    this.verificationCodeLength = this.commonConfigDataService.getVerificationCodeLength();
    this.setVerificationForm();

    if (!this.sessionStorage.getItem(this.verificationCodeSentStorageKey)) {
      this.sendVerificationCodeToUser();
      this.sessionStorage.setItem(this.verificationCodeSentStorageKey, '1');
    }
  }

  onEditIdentityFinished(isUpdated: boolean): void {
    this.editIdentityView = false;
    this.changeDetectorRef.markForCheck();
    if (isUpdated) {
      this.sendVerificationCodeToUser();
    }
  }

  setVerificationForm(): void {
    this.verificationForm = this.fb.group({
      code: ['', []],
    });
  }

  onInputChange(): void {
    if (this.verificationCode.includes(' ')) {
      this.verificationCode = this.verificationCode.trim();
    }

    this.changeAnimationVisibility(this.verificationCode.length > 0);

    if (this.verificationCode.length === this.verificationCodeLength && !this.isSubmitting) {
      if (this.isVerificationCodeValid()) {
        this.onSubmit();
      } else {
        this.verificationCodeControl.setErrors({ default: true });
      }
    }
  }

  onPaste(event: ClipboardEvent): void {
    const value = event.clipboardData.getData('text');
    this.verificationCode = value.trim().substr(0, this.verificationCodeLength);
  }

  resendCode(): void {
    this.trackVerificationResendCodeClicked();
    this.currIdentityInput$
      .pipe(
        filter((identityInput: string) => !!identityInput),
        take(1),
        switchMap((identityInput: string) => {
          return this.verificationV2DataService.sendOtp(identityInput, VerificationScope.OnBoarding);
        }),
      )
      .subscribe({
        next: () => {
          this.trackVerificationResendCodeResult(true);
          this.store.dispatch(
            toastActions.showToast({
              toastType: ToastType.Success,
              message: this.resendSuccessMessage,
            }),
          );
        },
        error: (error: HttpErrorResponse) => {
          this.trackVerificationResendCodeResult(false, error.error?.niStatusCode);
          this.handleSendOtpError(error);
        },
      });
  }

  onEditIdentityClicked(): void {
    this.editIdentityView = true;
    this.verificationCode = '';
    this.changeDetectorRef.markForCheck();
  }

  protected sendVerificationCodeToUser(): void {
    this.currIdentityInput$
      .pipe(
        filter((identityInput: string) => !!identityInput),
        take(1),
        switchMap((identityInput: string) => {
          return this.verificationV2DataService.sendOtp(identityInput, VerificationScope.OnBoarding);
        }),
      )
      .subscribe({
        next: () => {
          this.trackVerificationSendCodeResult(true);
        },
        error: (error: HttpErrorResponse) => {
          this.trackVerificationSendCodeResult(false, error.error?.niStatusCode);
          this.handleSendOtpError(error);
        },
      });
  }

  private isVerificationCodeValid(): boolean {
    return !!new RegExp(`^\\d{${this.verificationCodeLength}}$`).test(this.verificationCode);
  }

  private changeAnimationVisibility(isVisible: boolean): void {
    this.showAnimation = isVisible;
    this.changeDetectorRef.markForCheck();
  }

  private setIsSubmitting(isSubmitting: boolean): void {
    this.isSubmitting = isSubmitting;
    this.changeDetectorRef.markForCheck();
  }

  private onSubmit(): void {
    this.setIsSubmitting(true);
    this.currIdentityInput$
      .pipe(
        filter((identityInput: string) => !!identityInput),
        take(1),
        switchMap((identityInput: string) => {
          return this.verificationV2DataService.validateOtp(identityInput, this.verificationCode);
        }),
      )
      .pipe(
        tap(() => {
          this.trackVerificationValidateCodeResult(true);
          this.changeAnimationVisibility(false);
          this.loadIsIdentityVerified();
          this.completeStep();
        }),
        catchErrorAndLog((error: HttpErrorResponse) => {
          this.trackVerificationValidateCodeResult(false, error.error?.niStatusCode);
          this.handleValidateOtpError(error);
          return of(false);
        }),
        finalize(() => {
          this.setIsSubmitting(false);
        }),
      )
      .subscribe();
  }

  private handleSendOtpError(error: HttpErrorResponse): void {
    if (error.error?.niStatusCode in this.verificationCodeErrors) {
      this.verificationCodeControl.setErrors({ [error.error?.niStatusCode]: true });
      this.changeDetectorRef.markForCheck();
    } else {
      this.router.navigateByUrl(AppUrl.Error);
      this.abortOnboarding.emit();
    }
  }

  private handleValidateOtpError(error: HttpErrorResponse): void {
    if (error.error?.niStatusCode in this.verificationCodeErrors) {
      this.verificationCodeControl.setErrors({ [error.error?.niStatusCode]: true });
    } else {
      this.verificationCodeControl.setErrors({ default: true });
    }
    this.changeDetectorRef.markForCheck();
  }
}
