<div (niClickOutside)="closeMenu()" class="navigation-menu" data-test="navigation-menu" id="navigation-menu">
  <div (click)="toggleMenu()" data-test="navigation-menu-icon">
    <div [ngClass]="{ 'is-active': isMenuOpen }" class="menu-icon"></div>
  </div>
  <div *ngIf="isMenuOpen" class="menu-options" data-test="navigation-menu-options">
    <div class="tab-container">
      <ng-container *ngFor="let tab of tabs">
        <ni-expandable-menu-item
          #expandableMenuItem
          (closeOpenedMenu)="closeOpenedMenu()"
          (onClick)="onTabClick(tab)"
          [attr.data-menu-item]="tab.id"
          [tab]="tab"
        ></ni-expandable-menu-item>
      </ng-container>
      <div class="border"></div>
      <ni-language-switch (onLanguageChanged)="languageChanged($event)"></ni-language-switch>
      <div (click)="logout()" class="tab p-sm_lg">
        <img alt="logout" class="tab-icon" src="/assets/img/nav/logout.svg" />
        {{ 'HEADER.LOGOUT' | translate }}
      </div>
      <ni-referral-program class="tab p-sm_lg" [placement]="'header'" [icon]="true" />
    </div>
  </div>
</div>
