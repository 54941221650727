import { CurrencyPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, EnvironmentInjector, ErrorHandler } from '@angular/core';
import { TitleStrategy } from '@angular/router';
import {
  ApiDurationInterceptor,
  NI_METADATA_BUILDER,
  NI_RETRY_CALLBACK,
  RequestIdInterceptor,
  RetryInterceptor,
  ServiceWorkerInterceptor,
  SMARTYSTREETS_CONFIG,
  SURVICATE_CONFIG,
  WINDOW,
} from '@next-insurance/ng-core';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { AbstractChatbotCustomHandlerService } from './chatbot/components/rich-messages/services/abstract-chatbot-custom-handler-service';
import { smartyStreetsConfig } from './core/config/smartystreet.config';
import { survicateConfig } from './core/config/survicate.config';
import { metadataBuilderFactory } from './core/handlers/error-handler/meatadata-builder.factory';
import { NGErrorHandler } from './core/handlers/error-handler/ng-error-handler';
import { retryCallbackFactory } from './core/handlers/error-handler/on-api-retry-handler/retry-callback.factory';
import { ApiInterceptor } from './core/services/api-interceptor.service';
import { AppInitializationService } from './core/services/app-initialization.service';
import { ChatbotCustomHandlerService } from './core/services/chatbot-custom-handler.service';
import { TrackingService } from './core/services/tracking.service';
import { CustomTitlesStrategy } from './shared/misc/custom-titles-strategy';
import { HidePhoneNumberPipe } from './shared/pipes/hide-phone-number.pipe';

function initializeApp(appInitializationService: AppInitializationService): () => Promise<boolean> {
  return () => appInitializationService.initializeApp();
}

export const appProviders = [
  MessageService,
  CookieService,
  CurrencyPipe,
  HidePhoneNumberPipe,
  DynamicDialogConfig,
  DynamicDialogRef,
  provideHttpClient(withInterceptorsFromDi()),
  { provide: NI_METADATA_BUILDER, useFactory: metadataBuilderFactory, deps: [Store, CookieService] },
  { provide: NI_RETRY_CALLBACK, useFactory: retryCallbackFactory, deps: [TrackingService] },
  {
    provide: ErrorHandler,
    useFactory: (injector: EnvironmentInjector) => {
      return new NGErrorHandler(injector);
    },
    deps: [EnvironmentInjector],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: initializeApp,
    deps: [AppInitializationService],
    multi: true,
  },
  { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: RequestIdInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ApiDurationInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ServiceWorkerInterceptor, multi: true },
  { provide: WINDOW, useValue: window },
  { provide: SURVICATE_CONFIG, useValue: survicateConfig },
  { provide: SMARTYSTREETS_CONFIG, useValue: smartyStreetsConfig },
  { provide: AbstractChatbotCustomHandlerService, useClass: ChatbotCustomHandlerService },
  {
    provide: TitleStrategy,
    useClass: CustomTitlesStrategy,
  },
];
